import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from 'classnames';
import EnrollForm from '../forms/EnrollForm';
import StudentService from '../services/StudentService';
import SchoolYearService from '../services/SchoolYearService';
import SharedService from '../services/SharedService';
import EnrollTable from '../tables/EnrollTable';
import EnrollService from '../services/EnrollService';
import Enroll from '../models/Enroll';
import {connect} from 'react-redux';
import { addFilterEnroll } from '../redux/actions/filters';
import {useUI, usePageUI} from '../app/context/ui';

const EnrollPage = (props) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [enroll, setEnroll] = useState({});
  const [enrollAll, setEnrollAll] = useState([]);
  const [enrolls, setEnrolls] = useState([]);
  const [student, setStudent] = useState([]);
  const [schoolYear, setSchoolYear] = useState([]);
  const [levels, setLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [sections, setSections] = useState([]);
  const [limitEnroll, setLimitEnroll] = useState(25);
  const [filter, setFilter] = useState(null);
  const [filterEnroll, setFilterEnroll] = useState(props.filters.filterEnroll);
  const [page, setPage] = useState(1);
  const [isValid, setIsValid] = useState(false);
  const [pageEnroll, setPageEnroll] = useState(1);
  const [pagination, setPagination] = useState({
    activePage: 1,
    itemsCountPerpage: 10,
    totalItemsCount: 1,
    lastPage: 1
  });
  const [paginationEnroll, setPaginationEnroll] = useState({
    activePage: 1,
    itemsCountPerpage: 1,
    totalItemsCount: 1,
    lastPage: 1
  });
  
  
  const getEnrolls = async (p, lim, fil) => {
    setIsLoading(true);
    try {
      const r = await EnrollService.list(p,lim,fil);
      const res = r.data;
      setEnrolls(res.data.data);
      setPaginationEnroll({
        activePage: res.data['currentPage'],
        itemsCountPerpage: res.data['perPage'],
        totalItemsCount: res.data['total'],
        lastPage: res.data['lastPage']
      })
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const getEnrollsAll = async (fil) => {
    try {
      const res = await EnrollService.listEnrollsAll(fil);
      setEnrollAll(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  const getStudents = async (p, lim, fil) => {
    try {
      const r = await StudentService.list(p,lim,fil);
      setStudent(r.data.data)
      setPagination({
        activePage: r.data['currentPage'],
        itemsCountPerpage: r.data['perPage'],
        totalItemsCount: r.data['total'],
        lastPage: r.data['lastPage']
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getSchoolYear = async () => {
    try {
      const response = await SchoolYearService.list();
      // const list = response.data.data;
      setSchoolYear(response.data.data)
      // setSchoolYear(list.map((l) => {
      //   return {key: l.name, value: l.id}
      // }))
    } catch (error) {
      console.log(error)
    }
  }

  const getlevels = async () => {
    try {
      const response = await SharedService.listlevels();
      setLevels(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getGrades = async (levelId) => {
    try {
      const response = await SharedService.listGradesByLevel(levelId);
      setGrades(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getSections = async (gradeId, yearId) => {
    try {
      const response = await SharedService.listSectionsByGrade(gradeId, yearId);
      setSections(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const changePage = p => {
    setPage(p)
  }

  const changeSearch = value => {
    setFilter(value)
  }

  const changeLimitEnroll = lim => {
    setPageEnroll(1)
    setLimitEnroll(lim)
  }
  const changePageEnroll = p => {
    setPageEnroll(p)
  }

  const changeSearchEnroll = value => {
    props.dispatch(addFilterEnroll(value));
    setFilterEnroll(value)
  }

  const changeGrades = async (levelId) => {
    await getGrades(levelId);
  }
  const changeSections = async (gradeId, yearId) => {
    await getSections(gradeId, yearId);
  }

  const saveEnroll = async (data) => {
    blockUI.current.open(true, 'Estamos guardando...');
    try {
      await EnrollService.create(data);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Matrícula guardada correctamente', 'success');
      setActiveTab(1);
    } catch (error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
    }
  }

  const getEnroll = (data, actions) => {
    // eslint-disable-next-line default-case
    switch(actions){
      case 'payments' : history.push(`/enrolls/${data.id}/payments`, {enroll: data});
        break;
      case 'edit' : console.log(data) // Funcion para editar enroll
        break;
      case 'delete' : console.log(data) // Funcion para eliminar un enroll
    }
  }

  const openTabAdd = () => {
    setEnroll(Enroll);
    setIsValid(false);
    setActiveTab(2);
  };

  useEffect(() => {
    // Init Services
    EnrollService.init(props.user.accessToken, props.campus.id);
    StudentService.init(props.user.accessToken, props.campus.id);
    SharedService.init(props.user.accessToken, props.campus.id);
    SchoolYearService.init(props.user.accessToken, props.campus.id);
    // BI
    setEnroll(Enroll);
    (async function getRecords() {
      await getSchoolYear();
      await getlevels();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.campus]);

  useEffect(() => {
    
    (async function getRecords() {
      await getStudents(page, 10, filter);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, props.campus]);

  useEffect(() => {
    (async function getRecords() {
      await getEnrolls(pageEnroll, limitEnroll, filterEnroll);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitEnroll, pageEnroll, filterEnroll, props.campus, activeTab]);

	return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Matrícula</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">{props.user.school ? props.user.school : 'YAKHANA'}</li>
                <li className="breadcrumb-item active" aria-current="page">Matrícula</li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setActiveTab(1)}
                >
                  Listado
                        </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => openTabAdd()}
                >
                  Agregar
                        </NavLink>
              </NavItem>
            </Nav>

          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className={classnames(['fade show'])}>
              <EnrollTable
                data={enrolls}
                changeLimit={changeLimitEnroll}
                pagination={paginationEnroll}
                changePage={changePageEnroll}
                changeSearch={changeSearchEnroll}
                getEnroll={getEnroll}
                isLoading={isLoading}
                schoolYear={schoolYear}
                levels={levels}
                grades={grades}
                sections={sections}
                changeGrades={changeGrades}
                changeSections={changeSections}
                report={enrollAll}
              />
            </TabPane>
            <TabPane tabId={2} className={classnames(['fade show'])}>
              <EnrollForm
                enroll={Enroll}
                data={student}
                pagination={pagination}
                changePage={changePage}
                changeSearch={changeSearch}
                schoolYear={schoolYear}
                levels={levels}
                grades={grades}
                sections={sections}
                changeGrades={changeGrades}
                changeSections={changeSections}
                user={props.user}
                getEnroll={saveEnroll}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    campus: state.campus,
    filters: state.filters
  };
};

export default connect(mapStateToProps)(EnrollPage);
