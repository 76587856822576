import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FormHelperText } from "@mui/material";
import PaymentService from "../../../services/PaymentService";
import Payment from "../../../models/NewPayment";
import { Formik, Form } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DatePickerField from "../../../core/widgets/datepicker.widget";
import "dayjs/locale/es-mx";
import dayjs from "dayjs/";
import InputField from "../../../core/widgets/input.widget";
import SelectField from "../../../core/widgets/select.widget";
import * as yup from "yup";

const initialValues = {
  description: "",
  amount: 0,
  total: 0,
  paidAt: "",
  discount: "",
  penalty: "",
  paymentMethod: "",
  paymentMethodId: "",
  noOperation: "",
};

const PaymentSchema = yup.object().shape({
  paidAt: yup.date().required("Ingrese la fecha de pago"),
  amount: yup.number().required("El monto es requerido"),
  total: yup.number().required("El total es requerido"),
  penalty: yup.number(),
  discount: yup.number(),
  paymentMethod: yup.string().required("Ingrese la forma de pago"),
  description: yup.string(),
});

const EnrollPaymentsModal = ({
  payment,
  handlerOpenModal,
  user,
  isOpen,
  getPayment,
  className,
  paymentMethods,
}) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [pay, setPay] = useState(initialValues);
  const { voucherThumbnail, voucher } = data;
  console.log(data);
  const toggle = () => {
    handlerOpenModal(!show);
    setShow(!show);
    setData([]);
  };

  const isSending = useRef(false);

  useEffect(() => {
    PaymentService.init(user.accessToken);
  }, [user]);

  useEffect(() => {
    if (isOpen) {
      setData(payment);
      setPay({
        description: payment.description ?? "",
        amount: payment.amount ?? "",
        total: payment.amount ?? "",
        paidAt: payment.paidAt ?? "",
        paymentMethod: payment.methodId ?? "",
        paymentMethodId: payment.paymentMethodId ?? "",
        noOperation: payment.noOperation ?? "",
      });
    }
    setShow(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if(isSending.current === true) {
      isSending.current = false;
      getPayment(data);
    }
  }, [data]);

  useEffect(() => {
    if (isSending.current === true) {
      isSending.current = false;
      getPayment(data);
    }
  }, [data]);

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setData({
      ...data,
      voucher : base64
    })
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggle}
        className={className}
        size="lg"
        style={{ maxWidth: "1000px", width: "100%" }}
      >
        <ModalHeader toggle={toggle}>Pago {data.month}</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={pay}
            validationSchema={PaymentSchema}
            onSubmit={(values) => {
              isSending.current = true;
              setData({
                ...data,
                total: values.total,
                penalty: values.penalty,
                discount: values.discount,
                status: 1,
                methodId: values.paymentMethod,
                paidAt: dayjs(values.paidAt).format("YYYY-MM-DD"),
                amount: values.amount,
                noOperation: values.noOperation
              });
              handlerOpenModal(!show);
              setData(Payment);
            }}
          >
            {({ handleChange, values, errors }) => (
              <Form>
                <div className="row">
                  <div className="col-md-7">
                    <div className="form-group row">
                      <label className="col-md-4 col-form-label">
                        Fecha de pago <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-8">
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="es-mx"
                        >
                          <DatePickerField name="paidAt" />
                        </LocalizationProvider>
                        {errors.paidAt && (
                          <FormHelperText className="text-danger text-error">
                            {errors.paidAt}
                          </FormHelperText>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4 col-form-label">
                        Pago <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-8">
                        <InputField
                          name="amount"
                          onChange={(e) => {
                            values.total =
                              e.target.value + values.penalty - values.discount;
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4 col-form-label">Mora</label>
                      <div className="col-md-8">
                        <InputField
                          type="number"
                          name="penalty"
                          onChange={(e) => {
                            values.total =
                              parseInt(values.amount) +
                              parseInt(e.target.value) -
                              parseInt(values.discount);
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4 col-form-label">
                        Descuento
                      </label>
                      <div className="col-md-8">
                        <InputField
                          type="number"
                          name="discount"
                          onChange={(e) => {
                            values.total =
                              parseInt(values.amount) +
                              parseInt(values.penalty) -
                              parseInt(e.target.value);
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-4 col-form-label">
                        Total <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-8">
                        <InputField name="total" />
                      </div>
                    </div>
                    {values.discount > 0 && (
                      <div className="form-group row">
                        <label className="col-md-4 col-form-label">
                          Descripción
                        </label>
                        <div className="col-md-8">
                          <InputField name="description" />
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <label className="col-md-4 col-form-label">
                        Forma de Pago <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-8">
                        <SelectField
                          name="paymentMethod"
                          data={paymentMethods.map((item) => {
                            return { key: item.name, value: item.id };
                          })}
                        />
                      </div>
                    </div>
                    {values.paymentMethod > 1 && (
                      <div className="form-group row">
                        <label className="col-md-4 col-form-label">
                          Nº Operación <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <InputField name="noOperation" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-5">
                    <div className="text-center pt3 mb5">
                      {voucherThumbnail && (
                        <img
                          src={voucherThumbnail ? voucherThumbnail : ""}
                          height="400px"
                        />
                      )}
                      {voucher && (
                        <img src={voucher ? voucher : ""} height="400px" />
                      )}
                    </div>
                    <div className="form-group row">
                      <label>Voucher</label>
                      <div>
                        <input
                          type="file"
                          name="voucher"
                          onChange={(e) => {
                            uploadImage(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="mr-1 btn btn-primary">
                    Pagar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EnrollPaymentsModal;
