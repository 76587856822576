import React, { useState, useEffect } from "react";
import User from '../models/User';
import { Formik, Form } from "formik";
import InputField from '../core/widgets/input.widget';
import SelectField from '../core/widgets/select.widget';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePickerField from '../core/widgets/datepicker.widget'
import 'dayjs/locale/es-mx';
import * as yup from "yup";


const digitsOnly = (value) => /^\d+$/.test(value);
const genderList = [
  {value: '', key: 'Seleccione un género'},
  {value: 'F', key: 'Femenino'},
  {value: 'M', key: 'Masculino'}
];

const statusList = [
  {value: '', key: 'Seleccione un estado'},
  {value: '1', key: 'Activo'},
  {value: '2', key: 'Inactivo'}
];

const UserSchema = yup.object().shape({
  dni: yup
  .string()
  .required('Campo requerido')
  .typeError('El DNI solo debe contener números.')
  .test('Digits only', 'El DNI solo debe contener números', digitsOnly)
  .test('len', 'El DNI debe tener 8 digitos.', val => val && val.toString().length === 8 ),
  firstName: yup.string().required('Campo obligatorio'),
  lastName: yup.string().required('Campo obligatorio'),
  surname: yup.string().required('Campo obligatorio'),
  email: yup.string().email().required('Campo obligatorio'),
  gender: yup.string().required('Campo obligatorio'),
  status: yup.number().required('Campo requerido'),
});

const UserForm = ({userData, getUserData, roles, campuses}) => {
  
  const dataInicial = User;
  const [user, setUser] = useState(dataInicial);

  useEffect(() => {
    setUser(userData);
  }, [userData])

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Nuevo Usuario</h3>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{...userData}}
                validationSchema={UserSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  values.campusId = values.campusId ? values.campusId.toString() : '1';
                  getUserData(values);
                }}
              >
                {({values}) => (
                  <Form>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">
                        DNI <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-7">
                        <InputField
                          name="dni"
                          inputProps={{
                            maxLength: 8
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">
                        Nombres <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-7">
                        <InputField name="firstName" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">
                        Ap. Paterno <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-7">
                        <InputField name="lastName" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">
                        Ap. Materno <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-7">
                        <InputField name="surname" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Género <span className="text-danger">*</span></label>
                      <div className="col-md-7">
                        <SelectField
                          name="gender"
                          data={genderList}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Fecha Nacimiento</label>
                      <div className="col-md-7">
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale='es-mx'
                        >
                          <DatePickerField
                            name='birthdate'
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Email <span className="text-danger">*</span></label>
                      <div className="col-md-7">
                        <InputField name="email" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Rol <span className="text-danger">*</span></label>
                      <div className="col-md-7">
                        <SelectField
                          name="role"
                          data={roles.map((item) => {
                            return {key: item.fullname, value: item.name}
                          })}
                        />
                      </div>
                    </div>
                    {
                      (values.role !== 'super-admin' && values.role !== 'admin' && values.role !== 'coordinator-main') &&
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">Sede <span className="text-danger">*</span></label>
                        <div className="col-md-7">
                          <SelectField
                            name="campusId"
                            data={campuses.map((item) => {
                              return {key: item.name, value: item.id}
                            })}
                          />
                        </div>
                      </div>
                    }
                    <div className="form-group row">
                      <label className="col-md-3 col-form-label">Estado <span className="text-danger">*</span></label>
                      <div className="col-md-7">
                        <SelectField
                          name="status"
                          data={statusList}
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <button type="submit" className="mr-1 btn btn-primary">
                        Guardar
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserForm;
