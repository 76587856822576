import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from "classnames";
import ExpenseService from '../services/ExpenseService';
import ExpenseTable from '../tables/ExpenseTable';
import ExpenseForm from '../forms/ExpenseForm';
import Expense from '../models/Expense';
import { connect } from "react-redux";
import { useUI, usePageUI } from '../app/context/ui';
import Swal from "sweetalert2";
import Moment from 'moment';

const ExpensePage = (props) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [activeTab, setActiveTab] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [expenseData, setExpenseData] = useState(Expense);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const dataInicial = Expense;
  const [pagination, setPagination] = useState({
    activePage: 1,
    itemsCountPerpage: 1,
    totalItemsCount: 1,
    lastPage: 1
  });

  useEffect(() => {
    ExpenseService.init(props.user.accessToken, props.campus.id)
  }, [props.campus, props.user]);

  useEffect(() => {
    getExpenses(page, limit);
  }, [page, limit, props.campus]);

  const getExpenses = async (p, l) => {
    setLoading(true);
    try {
      const response = await ExpenseService.list(p, l);
      setExpenses(response.data.data);
      setPagination({
        activePage: response.data.currentPage,
        itemsCountPerpage: response.data.perPage,
        totalItemsCount: response.data.total,
        lastPage: response.data.lastPage
      })
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };


  const getExpenseData = async(data) => {
    data.date = Moment(data.date).format('YYYY-MM-DD');
    blockUI.current.open(true, 'Estamos guardando...');
    let response = "";
    try {
      if (data.id) {
        response = await ExpenseService.update(data, data.id);
      } else {
        response = await ExpenseService.create(data);
      }
      getExpenses(page, limit);
      setActiveTab(1);
      blockUI.current.open(false);
      alertUI.current.open(true, response.data.message, 'success');
      resetExpense();
      setIsValid(true);
    } catch (error) {
      blockUI.current.open(false);
      console.log(error);
    }
  };


  const getData = (data, action) => {
    
    setExpenseData(data);
    if (action === "edit") {
      setActiveTab(2);
    } else {
      deleteExpense(data);
    }
  };

  const resetExpense = () => {
    setExpenseData(dataInicial);
  };

  const deleteExpense = (data) => {
    Swal.fire({
      title: "Estas Seguro?",
      text: "No podrás recuperar este registro eliminado!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#333",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        blockUI.current.open(true, 'Estamos eliminando...');
        try {
          const response = await ExpenseService.delete(data.id);
          if (response) {
            getExpenses(page, limit);
            blockUI.current.open(false);
            alertUI.current.open(true, response.data.message, 'success');
          }
        } catch (error) {
          blockUI.current.open(false);
          console.log(error);
        }
      }
    });
  };

  const openTabAdd = () => {
    resetExpense();
    setIsValid(false);
    setActiveTab(2);
  };

  const getPage = (p) => {
    setPage(p)
  }

  const getLimit = (l) => {
    setLimit(l)
  }

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Gastos</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {props.user.school ? props.user.school : 'YAKHANA'}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Gastos
                </li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setActiveTab(1)}
                >
                  Listado
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => openTabAdd()}
                >
                  Agregar
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className={classnames(["fade show"])}>
              <ExpenseTable
                isLoading={loading}
                data={expenses}
                getData={getData}
                pagination={pagination}
                getPage={getPage}
                getLimit={getLimit}
              />
            </TabPane>
            <TabPane tabId={2} className={classnames(["fade show"])}>
              <ExpenseForm
                getExpenseData={getExpenseData}
                expenseData={expenseData}
                isValid={isValid}
                user={props.user}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(ExpensePage);
