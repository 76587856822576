import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from "classnames";
import CampusService from '../services/CampusService';
import UserService from '../services/UserService';
import UserTable from '../tables/UserTable';
import UserForm from '../forms/UserForm';
import User from '../models/User';
import {connect} from 'react-redux';
import {useUI, usePageUI} from '../app/context/ui';
import Swal from "sweetalert2";


const UserPage = ({user, campus}) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [campuses, setCampuses] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState(User);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const [pagination, setPagination] = useState({
    activePage: 1,
    itemsCountPerpage: 1,
    totalItemsCount: 1
  });
  const dataInicial = User;

  useEffect(() => {
    UserService.init(user.accessToken);
    CampusService.init(user.accessToken, campus.id);
    getCampuses();
    getRoles();
  }, [campus, user]);


  useEffect(() => {
    getUsers(page, limit, filter);
  }, [page, limit, filter, campus]);

  const getUsers = async (p, l, s) => {
    setLoading(true);
    try {
      const response = await UserService.list(p, l, s);
      setUsers(response.data.data);
      setPagination({
        activePage: response.data.currentPage,
        itemsCountPerpage: response.data.perPage,
        totalItemsCount: response.data.total
      })
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCampuses = async () => {
    try {
      const response = await CampusService.list();
      setCampuses(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getRoles = async () => {
    try {
      const response = await UserService.listRoles();
      setRoles(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async (data) => {
    blockUI.current.open(true, 'Estamos guardando...');
    let response = "";
    try {
      if (data.id) {
        response = await UserService.update(data, data.id);
      } else {
        response = await UserService.create(data);
      }
      setActiveTab(1);
      blockUI.current.open(false);
      alertUI.current.open(true, response.data.message, 'success');
      resetUser();
      setIsValid(true);
      getUsers(page, limit);
    } catch (error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
    }
  };

  const getData = (data, action) => {

    setUserData(data);
    if (action === "edit") {
      setActiveTab(2);
    } else {
      deleteUser(data);
    }
  };

  const resetUser = () => {
    setUserData(dataInicial);
  };

  const deleteUser = (data) => {
    Swal.fire({
      title: "Estas Seguro?",
      text: "No podrás recuperar este registro eliminado!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#333",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        blockUI.current.open(true, 'Estamos eliminando...');
        try {
          const response = await UserService.delete(data.id);
          if (response) {
            getUsers(page, limit);
            blockUI.current.open(false);
            alertUI.current.open(true, response.data.message, 'success');
          }
        } catch (error) {
          console.log(error);
          blockUI.current.open(false);
          alertUI.current.open(true, 'Error', 'danger');
        }
      }
    });
  };

  const openTabAdd = () => {
    resetUser();
    setIsValid(false);
    setActiveTab(2);
  };

  const changeLimit = lim => {
    setPage(1)
    setLimit(lim)
  }
  const changePage = p => {
    setPage(p)
  }

  const changeSearch = value => {
    setFilter(value)
  }

  return (
    <>
      <div className="User-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Usuarios</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {user.school ? user.school : 'YAKHANA'}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Usuarios
                </li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setActiveTab(1)}
                >
                  Listado
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => openTabAdd()}
                >
                  Agregar
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="User-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className={classnames(["fade show"])}>
              <UserTable
                isLoading={loading}
                data={users}
                getData={getData}
                changeLimit={changeLimit}
                pagination={pagination}
                changePage={changePage}
                changeSearch={changeSearch}
              />
            </TabPane>
            <TabPane tabId={2} className={classnames(["fade show"])}>
              <UserForm
                getUserData={getUserData}
                userData={userData}
                isValid={isValid}
                campuses={campuses}
                roles={roles}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(UserPage);
