import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";

const yearList = [
  { value: 18, key: "2024" },
];

const FilterAcademy = ({
  listSections,
  sections,
  listCourses,
  courses,
  handleChange,
  values
}, props) => {
  const changeYear = (event) => {
    listSections(event.target.value);
    handleChange(event);
  };

  const changeSection = (event) => {
    listCourses(event.target.value);
    handleChange(event);
  };

  return (
    <div className="row">
      <div className="col-lg-2 col-md-6 col-sm-12">
        <FormControl fullWidth>
          <InputLabel id="year-id-label">Año Lectivo</InputLabel>
          <Select
            labelId="year-id-label"
            id="year-id"
            name="yearId"
            // value={values.yearId}
            label="Año Lectivo"
            onChange={changeYear}
          >
            <MenuItem value="">Seleccionar</MenuItem>
            {yearList?.map((year, index) => (
              <MenuItem key={index} value={year.value}>
                {year.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12">
        <FormControl fullWidth>
          <InputLabel id="section-id-label">Seccion</InputLabel>
          <Select
            labelId="section-id-label"
            id="section-id"
            name="sectionId"
            label="Seccion"
            onChange={changeSection}
          >
            <MenuItem key="10000" value=" ">
              Seleccionar
            </MenuItem>
            {sections?.map((section, index) => (
              <MenuItem key={index} value={section.id}>
                {section.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12">
        <FormControl fullWidth>
          <InputLabel id="course-id-label">Curso</InputLabel>
          <Select
            labelId="course-id-label"
            id="course-id"
            name="courseId"
            label="Curso"
            onChange={handleChange}
          >
            <MenuItem key="10000" value=" ">
              Seleccionar
            </MenuItem>
            {courses?.map((course, index) => (
              <MenuItem key={index} value={course.id}>
                {course.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="col-lg-2 col-md-6 col-sm-12">
        <FormControl fullWidth>
          <InputLabel id="period-id-label">Bimestre</InputLabel>
          <Select
            labelId="period-id-label"
            id="period-id"
            name="periodId"
            label="Bimestre"
            onChange={handleChange}
          >
            <MenuItem value="">Seleccionar</MenuItem>
            <MenuItem value={1}>Bimestre 1</MenuItem>
            <MenuItem value={2}>Bimestre 2</MenuItem>
            <MenuItem value={3}>Bimestre 3</MenuItem>
            <MenuItem value={4}>Bimestre 4</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="col-lg-2 col-md-6 col-sm-12">
        <Button color="primary" variant="contained" type="submit">
          Buscar
        </Button>
      </div>
    </div>
  );
};

export default FilterAcademy;
