const Agent = {
    id: '',
    dni: '',
    firstName: '',
    lastName: '',
    surname: '',
    phone: '',
    address: '',
    role: 'agent',
    gender: '',
    email: ''
}

export default Agent