import { useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Stack,
  TableContainer,
  Paper,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import { upperCase } from "lodash";
import {useHistory } from 'react-router-dom';

export const ProfileListResults = ({
  profiles,
  count,
  deleteProfile,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState('');

  const handleClickOpen = (val) => {
    // console.log(val);
    setProfile(val);
    setOpen(true);
  };

  const handleClose = () => {
    deleteProfile(profile);
    setOpen(false);
  };

  return (
    <>
      <Card {...rest}>
        <TableContainer component={Paper}>
          <Table sx={{ maxHeight: 440 }}>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Sección</TableCell>
                <TableCell>Curso</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {profiles?.map((profile, index) => (
                <TableRow
                  hover
                  key={profile.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{upperCase(profile.sectionName)}</TableCell>
                  <TableCell>{upperCase(profile.courseName)}</TableCell>
                  <TableCell>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleClickOpen(profile)}
                      >
                        Eliminar
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Estas seguro que deseas eliminar este curso?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={handleClose} autoFocus>
              Si
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </>
  );
};
