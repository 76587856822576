import React from 'react';
import { Link, useHistory} from 'react-router-dom';
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import { removeUser } from '../../redux/actions/user';
import { removeCampus } from '../../redux/actions/campus';

const Logout = (props) => {

  const history = useHistory();

  const handlerLogout = () => {
    Swal.fire({
      title: "Deseas salir del sistema?",
      showCancelButton: true,
      cancelButtonColor: "#dc3545",
      confirmButtonColor: "#17a2b8",
      cancelButtonText: "No",
      confirmButtonText: "Si",
    }).then(result => {
      if (result.value) {
        props.dispatch({type: 'LOGOUT'});
        history.push('/login');
      }
    });
  }

  return (
    <Link onClick={() => handlerLogout()} className="nav-link icon settingbar" to='#'>
      <i className="fe fe-power" />
    </Link>
  );
}

export default connect(null)(Logout);
