import React, {useState} from 'react';
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
// import ReportPendientTable from '../tables/ReportPendientTable';
import ReportPaymentsByStatusTable from '../tables/ReportPaymentsByStatusTable';
import UsersAllTable from '../tables/UsersAllTable';

const ReportsPage = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const openTabUsers = () => {
    setActiveTab(2);
  };

  const openTabPendient = () => {
    setActiveTab(3);
  };

  const setFilter = (filter) => {
    console.log(filter);
  }
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Reportes</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {props.user.school ? props.user.school : 'YAKHANA'}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Reportes
                </li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setActiveTab(1)}
                >
                  Pagos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => openTabUsers()}
                >
                  Usuarios
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 3 })}
                  onClick={() => openTabPendient()}
                >
                  Morosidad
                </NavLink>
              </NavItem> */}
            </Nav>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className={classnames(["fade show"])}>
              <ReportPaymentsByStatusTable setFilter={setFilter} />
            </TabPane>
            <TabPane tabId={2} className={classnames(["fade show"])}>
              <UsersAllTable />
            </TabPane>
            {/* <TabPane tabId={3} className={classnames(["fade show"])}>
              <ReportPendientTable />
            </TabPane> */}
          </TabContent>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(ReportsPage);