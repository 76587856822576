import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const SectionSchema = yup.object().shape({
  code: yup.string().required("Campo requerido"),
  shortCode: yup.string().required("Campo requerido"),
  name: yup.string().required("Campo requerido"),
  gradeId: yup.number().required("Campo requerido"),
});

const SectionForm = (props) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(SectionSchema),
  });
  const [section, setSection] = useState({
    id: '',
    code: '',
    shortCode: '',
    name: '',
    gradeId: '',
  });

  useEffect(() => {
    setSection(props.sectionData);
  }, [props.sectionData]);

  const handlerChange = (e) => {
    setSection({
      ...section,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    props.getSectionData(section);
  };
  const {name, code, shortCode, gradeId} = section;
  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Nueva Sección</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Grado <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-9">
                    <select
                      className={
                        errors.gradeId
                          ? "form-control input-height is-invalid"
                          : "form-control input-height"
                      }
                      name="gradeId"
                      value={gradeId}
                      onChange={handlerChange}
                      ref={register}
                    >
                      <option value="">Seleccione</option>
                      {props.grades.map((item) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.code} - {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.gradeId && (
                      <span className="invalid-feedback">
                        {errors.gradeId.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Código <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className={
                        errors.code ? "form-control is-invalid" : "form-control"
                      }
                      name="code"
                      value={code}
                      onChange={handlerChange}
                      ref={register}
                      error={errors.code}
                      maxLength={4}
                    />
                    {errors.code && (
                      <span className="invalid-feedback">
                        {errors.code.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Código corto <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className={
                        errors.shortCode ? "form-control is-invalid" : "form-control"
                      }
                      name="shortCode"
                      value={shortCode}
                      onChange={handlerChange}
                      ref={register}
                      error={errors.shortCode}
                      maxLength={4}
                    />
                    {errors.shortCode && (
                      <span className="invalid-feedback">
                        {errors.shortCode.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Nombre <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className={
                        errors.name ? "form-control is-invalid" : "form-control"
                      }
                      name="name"
                      value={name}
                      onChange={handlerChange}
                      ref={register}
                      error={errors.name}
                    />
                    {errors.name && (
                      <span className="invalid-feedback">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="mr-1 btn btn-primary">
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionForm;
