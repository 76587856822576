import React, { Component } from 'react';
import { connect } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { Switch, Route, Link, PrivateRoute } from 'react-router-dom';
import Logout from '../Authentication/logout';
import DefaultLink from './DefaultLink';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import Routes from '../Route';
import ProtectedRoute from '../ProtectedRoute';
import {
	openLeftSidebarAction,
} from '../../actions/settingsAction';
import { universityMenu, adminMenu } from './metisMenu';
import {UIPageProvider} from '../../app/context/ui';
import {has} from 'lodash';

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			settingTab: 'Settings',
			rightTab: 'statistics',
			menuType: false,
			toggleThemeBar: false,
			openChatBar: false,
		};
	}
	closeBar = () => {
		this.setState({ toggleThemeBar: false })

	}
	toggleAction = () => {
		const { menuGrid, menuGridAction } = this.props
		this.setState({ menuType: false });
		menuGridAction(!menuGrid)
	}
	render() {
		const {
			openLeftSidebarAction, toggleLeftBar, isMinSidebar, menuGrid
		} = this.props
		const { menuType} = this.state;
		const modules = has(this.props.user, 'modules') ? this.props.user.modules : [];
		return (
			<>
				<div id="main_content">
					{/* <div className="page-loader-wrapper">
						<div className="loader">
						</div>
					</div> */}
					<div id="header_top" className={`header_top ${isMinSidebar ? 'dark' : ""}`}>
						<div className="container">
							<div className="hleft">
								<Link className="header-brand" to="/"><i className="fa fa-graduation-cap brand-logo"></i></Link>
								<div className="dropdown">
									<span onClick={() => openLeftSidebarAction(!toggleLeftBar)} className="nav-link icon menu_toggle"><i
										className="fe fe-align-center"></i>
									</span>
								</div>
							</div>
							<div className="hright">
								<Logout />
							</div>
						</div>
					</div>

					{/* <!-- Start Main leftbar navigation --> */}
					<div id="left-sidebar" className="sidebar">
						<h5 className="brand-name">{this.props.user.school ? this.props.user.school : 'YAKHANA'}</h5>
						<ul className="nav nav-tabs">
							<li className="nav-item" data-toggle="tab"><span className="nav-link"></span></li>
							{/* <li className="nav-item"><span className={`nav-link ${!menuType ? ' active' : ''}`} data-toggle="tab" onClick={() => this.setState({ menuType: false })}>Colegio</span></li>
							<li className="nav-item"><span className={`nav-link ${menuType ? ' active' : ''}`} data-toggle="tab" onClick={() => this.setState({ menuType: true })}>Admin</span></li> */}
						</ul>
						{menuGrid ?
							<>
								<div className="tab-content mt-3">
									<div className={`tab-pane fade${!menuType ? ' show active' : ''}`} id="menu-uni" role="tabpanel">
										<nav className="sidebar-nav">
											<MetisMenu
												className=""
												content={universityMenu}
												noBuiltInClassNames={true}
												classNameContainer="metismenu grid"
												classItemActive="active"
												classNameLink=""
												iconNamePrefix="fa fa-"
												LinkComponent={(e) => <DefaultLink itemProps={e} />}
											/>
										</nav>
									</div>
									<div className={`tab-pane fade${menuType ? ' show active' : ''}`} id="menu-admin" role="tabpanel">
										<nav className="sidebar-nav">
											<MetisMenu
												className=""
												content={adminMenu}
												noBuiltInClassNames={true}
												classNameContainer="metismenu grid"
												classItemActive="active"
												classNameLink=""
												iconNamePrefix="fa fa-"
												LinkComponent={(e) => <DefaultLink itemProps={e} />}
											/>
										</nav>
									</div>
								</div>
							</>
							:
							<div className="tab-content mt-3">
								<div className={`tab-pane fade${!menuType ? ' show active' : ''}`} id="menu-uni" role="tabpanel">
									<nav className="sidebar-nav">
										<MetisMenu
											className="sidebar-nav"
											content={modules}
											noBuiltInClassNames={true}
											classNameContainer="metismenu"
											classItemActive="active"
											classNameLink=""
											iconNamePrefix="fa fa-"
											LinkComponent={(e) => <DefaultLink itemProps={e} />}
										/>
									</nav>
								</div>
								<div className={`tab-pane fade${menuType ? ' show active' : ''}`} id="menu-admin" role="tabpanel">
									<nav className="sidebar-nav">
										<MetisMenu
											className="sidebar-nav"
											content={adminMenu}
											noBuiltInClassNames={true}
											classNameContainer="metismenu"
											classItemActive="active"
											classNameLink=""
											iconNamePrefix="fa fa-"
											LinkComponent={(e) => <DefaultLink itemProps={e} />}
										/>
									</nav>
								</div>
							</div>

						}
					</div>
					<div className="page" onClick={() => this.closeBar()}>
						<Header />
						<UIPageProvider>
							<Switch>
								{Routes.map((layout, i) => {
									if(layout.component === 'Login') {
										return <Route key={i} exact={layout.exact} path={layout.path} roles={layout.roles} component={layout.component} />
									}else{
										return <ProtectedRoute key={i} exact={layout.exact} roles={layout.roles} path={layout.path} component={layout.component} />
									}
								})}
							</Switch>
						</UIPageProvider>
						<Footer />
					</div>
				</div >
			</>
		)
	}
}

const mapStateToProps = state => ({
	toggleLeftBar: state.settings.isToggleLeftBar,
	menuGrid: state.settings.isMenuGrid,
	user: state.user
})

const mapDispatchToProps = dispatch => ({
	openLeftSidebarAction: (e) => dispatch(openLeftSidebarAction(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
