import React, {useState, useEffect, useReducer} from 'react';
import SharedService from '../../services/SharedService';
import SchoolYearService from '../../services/SchoolYearService';
import ReportService from '../../services/ReportService';
import { connect } from 'react-redux';
import Moment from 'moment';


const appReducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_DATA': 
      return {
        ...state,
        data: action.payload
      };
    case 'LOAD_GRADES': 
      return {
        ...state,
        grades: action.payload
      };
    case 'LOAD_SECTIONS': 
      return {
        ...state,
        sections: action.payload
      };
    case 'LOAD_YEAR': 
      return {
        ...state,
        schoolYear: action.payload
      };
    case 'SELECT_LEVEL': 
      return {
        ...state,
        level: action.payload
      };
    case 'SELECT_GRADE': 
      return {
        ...state,
        grade: action.payload
      };
    case 'SELECT_SECTION': 
      return {
        ...state,
        section: action.payload
      };
    case 'SELECT_YEAR': 
      return {
        ...state,
        year: action.payload
      };
    case 'SELECT_MONTH': 
      return {
        ...state,
        month: action.payload
      };
    case 'SELECT_START_DATE': 
      return {
        ...state,
        start_date: action.payload
      };
    case 'SELECT_END_DATE': 
      return {
        ...state,
        end_date: action.payload
      };
    case 'FILTER_DATA': 
      return {
        ...state,
        filter: action.payload
      };
    case 'SELECT_STATUS': 
      return {
        ...state,
        status: action.payload
      };
    case 'SELECT_TYPE': 
      return {
        ...state,
        enroll_type: action.payload
      };
  }

};

const FilterByStatus = (props) => {
  Moment.locale('es');
  const event = new Date();
  const options = { month: 'long'};
  const initialState = {
    data : [],
    year: '',
    level: '',
    grade: '',
    section: '',
    status: '',
    enroll_type: '',
    month: event.toLocaleDateString('es-PE', options),
    grades: [],
    sections: [],
    schoolYear: [],
    filter: `&school_year_id=15&status=1&month=${event.toLocaleDateString('es-PE', options)}&start_date=${Moment().format('yyyy-MM-DD')}&end_date=${Moment().format('yyyy-MM-DD')}`,
    start_date: Moment().format('yyyy-MM-DD'),
    end_date: Moment().format('yyyy-MM-DD'),
  };

  const [state, dispatch] = useReducer(appReducer, initialState);

  

  const getGrades = async (levelId) => {
    try {
      const response = await SharedService.listGradesByLevel(levelId);
      dispatch({type: 'LOAD_GRADES', payload: response.data.data})
    } catch (error) {
      console.log(error)
    }
  }

  const getSections = async (gradeId) => {
    try {
      const response = await SharedService.listSectionsByGrade(gradeId, state.year);
      dispatch({type: 'LOAD_SECTIONS', payload: response.data.data})
    } catch (error) {
      console.log(error)
    }
  }

  const getSchoolYear = async () => {
    try {
      const response = await SchoolYearService.list();
      dispatch({type: 'LOAD_YEAR', payload: response.data.data});
    } catch (error) {
      console.log(error)
    }
  }

  const handleGradesChange = e => {
    dispatch({type: 'SELECT_LEVEL', payload: e.target.value})
    getGrades(e.target.value);
  }
  const handleSectionsChange = e => {
    dispatch({type: 'SELECT_GRADE', payload: e.target.value})
    getSections(e.target.value);
  }

  const onSubmit = (event) => {
    event.preventDefault();

    const y = state.year ? '&school_year_id=' + state.year : '';
    const l = state.level ? '&level_id=' + state.level : '';
    const g = state.grade ? '&grade_id=' + state.grade : '';
    const s = state.section ? '&section_id=' + state.section : '';
    const m = state.month ? '&month=' + state.month : '';
    const st = state.status ? '&payment_status=' + state.status : '';
    const et = state.enroll_type ? '&enroll_type=' + state.enroll_type : '';
    const sd = state.start_date ? '&start_date=' + state.start_date : '';
    const ed = state.end_date ? '&end_date=' + state.end_date : '';
    const payload = y + l + g + s + m + st + et + sd + ed;

    dispatch({type: 'FILTER_DATA', payload: payload});
    props.setFilter(payload);
  }

  useEffect(() => {
    ReportService.init(props.user.accessToken, props.campus.id);
    SharedService.init(props.user.accessToken, props.campus.id);
    SchoolYearService.init(props.user.accessToken, props.campus.id);
    props.setFilter(initialState.filter);
    getSchoolYear();
  }, [props.campus]);
  
  return (
    <>
       <div className="card">
        <div className="card-body">
            <form  onSubmit={onSubmit}>
              <div className="form-row">
                <div className="form-group col-md-3 offset-3">
                  <label htmlFor="year">Desde</label>
                  <input 
                    type="date"
                    name="start_date"
                    className="form-control"
                    value={state.start_date}
                    onChange={e => dispatch({type: 'SELECT_START_DATE', payload: e.target.value})}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="year">Hasta</label>
                  <input 
                    type="date"
                    name="end_date"
                    className="form-control"
                    value={state.end_date}
                    onChange={e => dispatch({type: 'SELECT_END_DATE', payload: e.target.value})}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-1">
                  <label htmlFor="year">Año</label>
                  <select
                    value={state.year}
                    name="year"
                    className="form-control"
                    onChange={e => dispatch({type: 'SELECT_YEAR', payload: e.target.value})}
                  >
                    <option value="">Seleccionar</option>
                  {
                    state.schoolYear.map(item => {
                      return(
                        <option value={item.id} key={item.id}>{item.name}</option>
                      )
                    })
                  }
                  </select>
                  
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="year">Nivel</label>
                  <select value={state.level} name="level" className="form-control" onChange={handleGradesChange}>
                    <option value="">Seleccionar</option>
                    <option value="1">Inicial</option>
                    <option value="2">Primaria</option>
                    <option value="3">Secundaria</option>
                    <option value="4">RESERVADO</option>
                  </select>
                  
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="grade">Grado</label>
                  <select value={state.grade} name="grade" className="form-control" onChange={handleSectionsChange}>
                    <option value="">Seleccionar</option>
                    {
                      state.grades.map(item => {
                        return(
                          <option value={item.id} key={item.id}>{item.name}</option>
                        )
                      })
                    }
                  </select>
               
                </div>
                <div className="form-group col-md-1">
                  <label htmlFor="section">Sección</label>
                  <select value={state.section} name="section" className="form-control" onChange={e => dispatch({type: 'SELECT_SECTION', payload: e.target.value})}>
                    <option value="">Seleccionar</option>
                    {
                      state.sections.map(item => {
                        return(
                          <option value={item.id} key={item.id}>{item.shortCode}</option>
                        )
                      })
                    }
                  </select>
                  
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="month">Matricula / Pensión</label>
                  <select value={state.month} name="month" className="form-control" onChange={e => dispatch({type: 'SELECT_MONTH', payload: e.target.value})}>
                    <option value="">Seleccionar</option>
                    <option value="enroll">Matricula</option>
                    <option value="Marzo">Marzo</option>
                    <option value="Abril">Abril</option>
                    <option value="Mayo">Mayo</option>
                    <option value="Junio">Junio</option>
                    <option value="Julio">Julio</option>
                    <option value="Agosto">Agosto</option>
                    <option value="Septiembre">Septiembre</option>
                    <option value="Octubre">Octubre</option>
                    <option value="Noviembre">Noviembre</option>
                    <option value="Diciembre">Diciembre</option>
                    <option value="all">Todo</option>
                  </select>
                  
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="enroll_type">Modalidad</label>
                  <select value={state.enroll_type} name="enroll_type" className="form-control" onChange={e => dispatch({type: 'SELECT_TYPE', payload: e.target.value})}>
                    <option value="">Seleccionar</option>
                    <option value="1">Virtual</option>
                    <option value="2">Semiprencial</option>
                    <option value="3">Presencial</option>
                  </select>
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="status">Estado</label>
                  <select value={state.status} name="status" className="form-control" onChange={e => dispatch({type: 'SELECT_STATUS', payload: e.target.value})}>
                    <option value="">Seleccionar</option>
                    <option value="1">Pagado</option>
                    <option value="2">Pendiente</option>
                    <option value="3">Exonerado</option>
                    <option value="4">Compromiso de Pago</option>
                    <option value="5">Retirado</option>
                  </select>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12 text-center">
                  <button type="submit" className="center btn btn-primary">
                    Buscar
                  </button>
                </div>
              </div>
            </form>
          </div>
       </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(FilterByStatus);