import React, {useState} from 'react'
import Pagination from 'react-js-pagination';
import Spinner from '../components/Shared/Spinner';

const StudentTable = (props) => {
  const [show, setShow] = useState(false)
  const [limit, setLimit] = useState(25)
  const [search, setSearch] = useState('')
  const data = props.data

  const handlerChange = e => {
    setLimit(e.target.value)
    props.changeLimit(e.target.value)
  }

  const handlePageChange = page => {
    props.changePage(page)
  }

  const handlerChangeSearch = e => {
    setSearch(e.target.value)
    props.changeSearch(e.target.value)
  }

  const editStudent = item => {
    props.getStudent(item, 'edit')
  }

  return (
    <>
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="input-group">
              <input type="search" className="form-control" onChange={handlerChangeSearch} placeholder="Buscar" value={search} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="text-right mb-2">
      <div className="offset-10 col-sm-2">
        <select className="form-control input-height" onChange={handlerChange} value={limit}>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    </div>
    <div className="table-responsive card">
      <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
        <thead>
          <tr>
            <th>Imagen</th>
            <th>Código</th>
            <th>DNI</th>
            <th>Apellido paterno</th>
            <th>Apellido materno</th>
            <th>nombres</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="w60">
                    <img className="avatar" src={`../assets/images/xs/avatar${item.gender === 'M' ? 2 : 1}.jpg`} alt="" />
                  </td>
                  <td>{ item.code }</td>
                  <td>{ item.dni }</td>
                  <td>{ item.lastName }</td>
                  <td>{ item.surname }</td>
                  <td>{ item.firstName }</td>
                  <td>
                    {/* <button type="button" className="btn btn-icon btn-sm" title="View"><i className="fa fa-eye"></i></button> */}
                    <button type="button" className="btn btn-icon btn-sm" title="Edit" onClick={() => editStudent(item)}><i className="fa fa-edit"></i></button>
                    {/* <button onClick={() => setShow(true)} type="button" className="btn btn-icon btn-sm js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger"></i></button> */}
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
        { props.isLoading && <Spinner /> }
        { props.pagination.lastPage > 1 &&
        <div className="d-flex justify-content-center mt-20">
          <Pagination
            activePage={props.pagination.activePage}
            itemsCountPerPage={parseInt(props.pagination.itemsCountPerPage)}
            totalItemsCount={props.pagination.totalItemsCount}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass='page-item '
            linkClass='page-link'
          />        
        </div>
      }
    </div>
    </>
  )
}

export default StudentTable