import React, {createRef, useMemo} from 'react';
import AlertUI from "../../components/Shared/AlertUI";
import BlockUI from "../../components/Shared/BlockUI";

const UIContext = React.createContext({});
const UIPageContext = React.createContext({});
const alertUI = createRef();
const blockUI = createRef();

const UIPageProvider = props => {

  const rootPageUI = useMemo(() => {
    return ({
      alertUI,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <UIPageContext.Provider value={rootPageUI}>
      <div className="section-body mt-2" style={{ marginTop: "0 !important"}}>
        <div className="container-fluid">
          <AlertUI ref={alertUI} />
        </div>
      </div>
      {props.children}
    </UIPageContext.Provider>
  );
};

const UIProvider = props => {

  const rootUI = useMemo(() => {
    return ({
      blockUI,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <UIContext.Provider value={rootUI}>
      <BlockUI ref={blockUI}>
        {props.children}
      </BlockUI>
    </UIContext.Provider>
  );
};

const useUI = () => {
  return React.useContext(UIContext);
}

const usePageUI = () => {
  return React.useContext(UIPageContext);
}

export {UIProvider, useUI, UIPageProvider, usePageUI};
