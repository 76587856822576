import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from "classnames";
import PaymentService from '../services/PaymentService';
import ProductService from '../services/ProductService';
import PaymentTable from '../tables/PaymentTable';
import PaymentForm from '../forms/PaymentForm';
import Payment from '../models/Payment';
import { connect } from "react-redux";
import { useUI, usePageUI } from '../app/context/ui';
import Swal from "sweetalert2";
import Moment from 'moment';

const PaymentPage = (props) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();

  const [activeTab, setActiveTab] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [payments, setPayments] = useState([]);
  const [paymentMethods,setPaymentMethods] = useState([]);
  const [paymentOthersMethods,setPaymentOthersMethods] = useState([]);
  const [products, setProducts] = useState([]);
  const [paymentData, setPaymentData] = useState(Payment);
  const dataInicial = Payment;
  const [pagination, setPagination] = useState({
    activePage: 1,
    itemsCountPerpage: 1,
    totalItemsCount: 1,
    lastPage: 1
  });

  useEffect(() => {
    PaymentService.init(props.user.accessToken, props.campus.id);
    ProductService.init(props.user.accessToken, props.campus.id);
    getProducts(0);
    getPaymentMethods();
  }, [props.campus, props.user]);

  useEffect(() => {
    getPayments(page, limit);
  }, [page, limit, ]);

  const getPayments = async (p, l) => {
    setLoading(true);
    try {
      const response = await PaymentService.list(p, l);
      let paymentTem = [];
      if(response) {
        response.data.data.forEach(item => {
          const res = {
            id: item.id,
            paidAt: Moment(item.paidAt).format('YYYY-MM-DD'),
            amount: item.amount,
            total: item.total,
            userId: item.userId,
            userFullName: item.user.fullName,
            campusId: item.campusId,
            productId: item.productId,
            productName: item.product.name,
            methodId: item.methodId,
            description: item.description ? item.description : '',
            noOperation: item.noOperation
          }
          paymentTem.push(res)
        })
      }
      setPayments(paymentTem);
      setPagination({
        activePage: response.data.currentPage,
        itemsCountPerpage: response.data.perPage,
        totalItemsCount: response.data.total,
        lastPage: response.data.lastPage
      })
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getProducts = async (l) => {
    setLoading(true);
    try {
      const response = await ProductService.list(l);
      setProducts(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentMethods = async () => {
    try {
      const response = await PaymentService.listMethods();
      setPaymentMethods(response.data);
    } catch (error) {
      console.log(error)
    }
  }

  const getOthersPaymentMethods = async (parentId) => {
    try {
      const response = await PaymentService.listMethodsBank(parentId);
      setPaymentOthersMethods(response.data);
    } catch (error) {
      console.log(error)
    }
  }

  const getPaymentData = async(data) => {
    blockUI.current.open(true, 'Estamos guardando...');
    let response = "";
        try {
          if (data.id) {
            response = await PaymentService.update(data, data.id);
          } else {
            response = await PaymentService.create(data);
          }
          setActiveTab(1);
          getPayments(page, limit);
          blockUI.current.open(false);
          alertUI.current.open(true, response.data.message, 'success');
          resetPayment();
          setIsValid(true);
        } catch (error) {
          blockUI.current.open(false);
          console.log(error);
          alertUI.current.open(true, 'Error', 'danger');
        }
  };

  const getData = (data, action) => {
    
    setPaymentData(data);
    if (action === "edit") {
      setActiveTab(2);
    } else {
      deletePayment(data);
    }
  };

  const resetPayment = () => {
    setPaymentData(dataInicial);
  };

  const deletePayment = (data) => {
    Swal.fire({
      title: "Estas Seguro?",
      text: "No podrás recuperar este registro eliminado!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#333",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        blockUI.current.open(true, 'Estamos Eliminando....');
        try {
          const response = await PaymentService.delete(data.id);
          if (response) {
            getPayments(page, limit);
            blockUI.current.open(false);
            alertUI.current.open(true, response.data.message, 'success');
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const openTabAdd = () => {
    resetPayment();
    setIsValid(false);
    setActiveTab(2);
  };

  const getMethod = (parentId) => {
    getOthersPaymentMethods(parentId);
  }

  const getPage = (p) => {
    setPage(p)
  }

  const getLimit = (l) => {
    setLimit(l)
  }

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Pagos</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {props.user.school ? props.user.school : 'YAKHANA'}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Pagos
                </li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setActiveTab(1)}
                >
                  Listado
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => openTabAdd()}
                >
                  Agregar
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className={classnames(["fade show"])}>
              <PaymentTable
                isLoading={loading}
                data={payments}
                getData={getData}
                pagination={pagination}
                getPage={getPage}
                getLimit={getLimit}
              />
            </TabPane>
            <TabPane tabId={2} className={classnames(["fade show"])}>
              <PaymentForm
                getPaymentData={getPaymentData}
                paymentData={paymentData}
                products={products}
                paymentMethods={paymentMethods}
                paymentOthersMethods={paymentOthersMethods}
                isValid={isValid}
                getMethod={getMethod}
                user={props.user}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(PaymentPage);
