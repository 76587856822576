import 'dayjs/locale/es-mx';
import dayjs from 'dayjs/';
const Payment = {
    id: '',
    description: '',
    userId: '',
    userFullName: '',
    amount: '',
    total: '',
    productId: '',
    paidAt: dayjs(null)
}

export default Payment;