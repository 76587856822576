const Campus = {
    id: '',
    code: '',
    name: '',
    company: '',
    phone: '',
    address: '',
    ruc: '',
    enrollAmount: '',
    pensionAmount: ''
}

export default Campus;