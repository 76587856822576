const initialState = {
  filterEnroll: "",
  filterAcademy: {
    yearId: 16,
    sectionId: "",
    courseId: "",
    periodId: "",
    campusId: ""
  },
};

export default (state = initialState, action) => {

  switch (action.type) {
    case "FILTER_ENROLL":
      return {
        ...state,
        filterEnroll: action.payload,
      };
    case "FILTER_ACADEMY":
      return{
        ...state,
        filterAcademy: action.payload
      }

    default:
      return state;
  }
};
