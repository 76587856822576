import React from 'react';
import Menu from './Menu';

const Layout = (props) => {
	return (
		<div id="main_content">
			<Menu {...props} />
		</div>
	);
}

export default Layout;
