import React from "react";
import Spinner from '../components/Shared/Spinner';

const ProductTable = (props) => {
  const data = props.data;

  const editProduct = (item) => {
    props.getData(item, "edit");
  };

  const deleteProduct = (item) => {
    props.getData(item, "delete");
  };

  return (
    <>
      <div className="table-responsive card">
        <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Monto</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>S/ {item.amount}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-icon btn-sm"
                      title="Edit"
                      onClick={() => editProduct(item)}
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      onClick={() => deleteProduct(item)}
                      type="button"
                      className="btn btn-icon btn-sm js-sweetalert"
                      title="Delete"
                      data-type="confirm"
                    >
                      <i className="fa fa-trash-o text-danger"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        { props.isLoading && <Spinner />}
      </div>
    </>
  );
};

export default ProductTable;
