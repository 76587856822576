import AppService from "./AppService";

class ProductService extends AppService {
  list(limit) {
    return this.http.get(`/products?limit=${limit}`);
  }

  create(data) {
    return this.http.post("/products", data);
  }

  update(data, id) {
    return this.http.patch(`/products/${id}`, data);
  }
  delete(id) {
    return this.http.delete(`/products/${id}`);
  }
}

export default new ProductService();
