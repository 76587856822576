import React, {useState, useEffect} from 'react';
import Agent from '../../models/Agent';
import AgentForm from '../../forms/AgentForm';
import UserService from '../../services/UserService';
import {useUI, usePageUI} from '../../app/context/ui';
import { connect } from 'react-redux';

const CreatePage = (props) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    UserService.init(props.user.accessToken, props.campus.id);
  }, [props.campus]);

  const saveAgent = async (agent) => {
    blockUI.current.open(true, 'Estamos guardando...');
    try {
      const response = await UserService.create(agent);
      if (response) {
        blockUI.current.open(false);
        alertUI.current.open(true, response.data.message, 'success');
        props.changeTab(1);
        setIsValid(true);
      }
    } catch (error) {
      blockUI.current.open(false);
      //alertUI.current.open(true, Object.values(error.response.data.errors)[0], 'danger');
      alertUI.current.open(true, 'Error', 'danger');
    }
  }

  const searchAgent = val => {
    props.searchAgent(val);
    setIsValid(false);
  }
  return(
    <>
      <AgentForm
        agentData = {Agent}
        agents={props.agents}
        searchAgent={searchAgent}
        saveAgent={saveAgent}
        isValid={isValid}
      />
    </>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(CreatePage);