import {types} from '../types';
import {loadedState} from '../state';

const initialState = {...loadedState.campus};

function campus(state = initialState, action) {
  switch (action.type) {
    case types.CAMPUS_REMOVE: {
        return null;
    }
    case types.CAMPUS_UPDATE:
    case types.CAMPUS_ADD: {
      return {...state, ...action.payload};
    }
    default:
      return state;
  }
}

export default campus;
