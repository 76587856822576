import Moment from 'moment';
const Enroll = {
    id: '',
    userId: '',
    userFullName: '',
    schoolYearId: '',
    campusId: '',
    sectionId: '',
    status: '',
    pensionAmount: '',
    enrollAmount: '',
    enroll_type: 3,
    comment: '',
    saleChannel: '',
    presentialPensionAmount: '',
    isScholarship: 0,
    createdAt: Moment().format('yyyy-MM-DD'),
    endAt: ''
}

export default Enroll;
