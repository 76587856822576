const NewPayment = {
    id: '',
    description: '',
    userId: '',
    userFullName: '',
    amount: '',
    total: 0,
    productId: '',
    paidAt: '',
    paymentMethod: '',
    paymentMethodId: '',
    noOperation: ''
}

export default NewPayment;