import { types } from "../types";

export function addFilterEnroll(payload) {
  return {
    type: types.FILTER_ENROLL,
    payload,
  };
}

export function addFilterAcademy(payload) {
  return {
    type: types.FILTER_ACADEMY,
    payload,
  };
}
