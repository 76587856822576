import React, { useState, useEffect } from "react";
import SettingModel from '../models/Setting';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const SettingSchema = yup.object().shape({
  attr: yup.string().required("Campo requerido"),
  val: yup.string().required("Campo requerido")
});

const SettingForm = (props) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(SettingSchema),
  });
  const dataInicial = SettingModel;
  const [setting, setSetting] = useState(dataInicial);

  useEffect(() => {
    setSetting(props.settingData);
  }, [props.settingData])

  // useEffect(() => {
  //   (async function getRecords() {
  //     await setSetting(dataInicial);
  //   })();
  // }, [props.isValid])

  const handlerChange = (e) => {
    setSetting({
      ...setting,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    props.getSettingData(setting);
  };

  const {attr, campusId, val} = setting;

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Nuevo Atributo</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Sede <span className="text-danger">*</span></label>
                  <div className="col-md-7">
                    <select
                      className={errors.campusId ? "form-control input-height is-invalid-select" : "form-control input-height"}
                      name="campusId"
                      onChange={handlerChange}
                      value={campusId}
                      ref={register}
                    >
                      <option value="" key="">Seleccione</option>
                      {
                        props.campuses.map(item => {
                          return(
                          <option value={item.id} key={item.id}>{item.name}</option>
                          )
                        })
                      }
                    </select>
                    {errors.campusId && <span className="text-danger text-error">{errors.campusId.message}</span>}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Nombre <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                    <input
                      type="text"
                      value={attr}
                      className={
                        errors.attr ? "form-control is-invalid" : "form-control"
                      }
                      name="attr"
                      onChange={handlerChange}
                      ref={register}
                      error={errors.attr}
                    />
                    {errors.attr && (
                      <span className="invalid-feedback">
                        {errors.attr.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Valor <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                    <input
                      type="text"
                      value={val}
                      className={
                        errors.val ? "form-control is-invalid" : "form-control"
                      }
                      name="val"
                      onChange={handlerChange}
                      ref={register}
                      error={errors.val}
                    />
                    {errors.val && (
                      <span className="invalid-feedback">
                        {errors.val.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="mr-1 btn btn-primary">
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingForm;
