export const types = {
    USER_ADD: 'USER_ADD',
    USER_UPDATE: 'USER_UPDATE',
    USER_REMOVE: 'USER_REMOVE',
    CAMPUS_ADD: 'CAMPUS_ADD',
    CAMPUS_UPDATE: 'CAMPUS_UPDATE',
    CAMPUS_REMOVE: 'CAMPUS_REMOVE',
    FILTER_ENROLL: 'FILTER_ENROLL',
    FILTER_ACADEMY: 'FILTER_ACADEMY'
  };
