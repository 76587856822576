import React, {forwardRef, useImperativeHandle, useState} from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

const BlockUI = forwardRef((props, ref) => {
  const messageDefault = props.hasOwnProperty('message') ? props.message : '';
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState(messageDefault);

  useImperativeHandle(ref, () => ({
    open: (visible, message = '') => {
      setVisible(visible);
      if (message !== '') {
        setMessage(message);
      }
    },
  }));

  return (
    <BlockUi
      tag="div"
      blocking={visible}
      message={message}>
      {props.children}
    </BlockUi>
  );
});

export default BlockUI;
