import { useState, useEffect, forwardRef } from "react";
import { Box, Container, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {connect} from 'react-redux';
import { TaskDialog } from "../components/task/task-dialog";
import { TaskListResults } from "../components/task/task-list-results";
import { TaskListToolbar } from "../components/task/task-list-toolbar";
import AcademyService from "../services/AcademyService";
import UserService from "../services/UserService";

const Tasks = ({user, campus}) => {

  const academyService = new AcademyService();
  const [tasks, setTasks] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState("");
  
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const getTasks = async (page) => {
    try {
      const resp = await academyService.listTasks(page);
      setTasks(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const changePage = (page) => {
    console.log(page);
    setPage(page);
  };
  const changeLimit = (limit) => {
    setLimit(limit);
  };

  const handleInput = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const loadData = () => {
    getTasks(1);
  };

  const downloadDocument = async (taskId) => {
    try {
      const response = await academyService.downloadGroup({task_id: taskId});
      const url = response.data.resourceUrl;
      
      let file = document.createElement('a');
      file.href = url;
      file.setAttribute('download', url.split('/').pop())
      file.setAttribute('target', '_blank')
      file.click();
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getTasks(page);
  }, [page, limit, search]);

  useEffect(() => {
    UserService.init(user.accessToken, campus.id);
    (async function getRecords() {
      getTasks();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, campus]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <TaskListToolbar onInput={handleInput} loadData={loadData} />
          <Box sx={{ mt: 3 }}>
            <TaskListResults
              changePage={changePage}
              changeLimit={changeLimit}
              count={tasks.total || 0}
              tasks = {tasks.data}
              downloadDocument={downloadDocument}
              campus={campus}
            />
          </Box>
        </Container>
      </Box>
      {/* <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar> */}
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    campus: state.campus,
    filters: state.filters
  };
};

export default connect(mapStateToProps)(Tasks);
