import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Alert} from 'reactstrap';

const AlertUI = forwardRef((props, ref) => {
  const messageDefault = props.hasOwnProperty('message') ? props.message : 'Recargue la aplicacion';
  const colorDefault = props.hasOwnProperty('color') ? props.color : 'success';

  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState(messageDefault);
  const [color, setColor] = useState(colorDefault);

  const onDismiss = () => setVisible(false);

  useImperativeHandle(ref, () => ({
    open: (visible, message = '', color = '') => {
      setVisible(visible);
      if (message !== '') {
        setMessage(message);
      }
      if (color !== '') {
        setColor(color);
      }
    },
  }));

  setTimeout(() => {
    setVisible(false)
  }, 5000)

  return (
    <Alert color={color} isOpen={visible} toggle={onDismiss}>
      {message}
    </Alert>
  );
});

export default AlertUI;
