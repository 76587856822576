import { useState, useEffect, forwardRef } from "react";
import { useSelector } from "react-redux";
import { Box, Container, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";
import { ProfileListToolbar } from "../components/profile/profile-list-toolbar";
import { ProfileListResults } from "../components/profile/profile-list-results";
import { ProfileDialog } from "../components/profile/profile-dialog";
import UserService from "../services/UserService";
import { useLocation } from "react-router-dom";

const initialValues = {
  school_year_id: "",
  section_id: "",
  course_id: "",
};

const Profiles = (props) => {
  const location = useLocation();
  const { teacher } = location.state;
  const [profiles, setProfiles] = useState([]);
  const [sections, setSections] = useState([]);
  const [courses, setCourses] = useState([]);
  const [year, setYear] = useState("");
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState(initialValues);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("");

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const getProfiles = async () => {
    try {
      const response = await UserService.listProfiles(18, teacher.id);
      setProfiles(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInput = (event) => {
    setSearch(event.target.value);
  };

  const handleModal = () => {
    setOpen(true);
  };

  const deleteProfile = async(p) => {
    try {
      await UserService.deleteProfile(p.id);
      getProfiles();
      showMessage("Perfil e;iminado!", "success");
    } catch (error) {
      console.log(error);
      showMessage("Se produjo un error!", "error");
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setCustomer(initialValues);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const showMessage = (txt, action) => {
    setMessage(txt);
    setAlert(action);
  };

  const searchSection = async (val) => {
    setYear(val);
    try {
      const response = await UserService.listSectionsByCampus(val);
      setSections(response.data);
    } catch (error) {}
  };

  const searchCourses = async (val) => {
    try {
      const response = await UserService.listCoursesByYear(year, val);
      setCourses(response.data);
    } catch (error) {}
  };

  const saveProfile = async (data) => {
    data.user_id = teacher.id;
    data.campus_id = props.campus.id;
    try {
      await UserService.createProfile(data);
      getProfiles();
      handleModalClose();
      setOpenAlert(true);
      showMessage("Profile guardado!", "success");
    } catch (error) {
      console.log(error);
      setOpenAlert(true);
      showMessage("Se produjo un error!", "error");
    }
  };

  useEffect(() => {
    // Init Services
    // BI

    UserService.init(props.user.accessToken, props.campus.id);
    (async function getRecords() {
      await getProfiles();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user, props.campus]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <ProfileListToolbar
            onInput={handleInput}
            openModal={handleModal}
            teacher={teacher}
          />
          <ProfileDialog
            open={open}
            closeModal={handleModalClose}
            customer={customer}
            sections={sections}
            courses={courses}
            saveProfile={saveProfile}
            searchSection={searchSection}
            searchCourses={searchCourses}
          />
          <Box sx={{ mt: 3 }}>
            <ProfileListResults
              profiles={profiles}
              deleteProfile={deleteProfile}
              teacher={teacher}
            />
          </Box>
        </Container>
      </Box>
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus,
    filters: state.filters,
  };
};

export default connect(mapStateToProps)(Profiles);
