import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from "classnames";
import SectionTable from "../tables/SectionTable";
import SharedService from "../services/SharedService";
import SectionService from "../services/SectionService";
import { connect } from "react-redux";
import { useUI, usePageUI } from '../app/context/ui';
import SectionForm from "../forms/SectionForm";
import Swal from "sweetalert2";

const SectionPage = (props) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [activeTab, setActiveTab] = useState(1);
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [grades, setGrades] = useState([]);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    activePage: 1,
    itemsCountPerpage: 1,
    totalItemsCount: 1,
    numPage: 1,
  });
  const [section, setSection] = useState({
    id: "",
    code: "",
    name: "",
    gradeId: "",
  });

  useEffect(() => {
    SectionService.init(props.user.accessToken, props.campus);
    SharedService.init(props.user.accessToken, props.campus);
    getGrades();
  }, [props.user, props.campus]);

  useEffect(() => {
    getSections(page, limit);
  }, [limit, page]);

  const getSections = async (p, lim) => {
    setLoading(true);
    try {
      const response = await SectionService.list(p, lim);
      setSections(response.data.data);
      setPagination({
        activePage: response.data.currentPage,
        itemsCountPerpage: response.data.perPage,
        totalItemsCount: response.data.total,
        numPage: response.data.lastPage,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getGrades = async () => {
    try {
      const response = await SharedService.listGrades();
      setGrades(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSectionData = async(data) => {
    blockUI.current.open(true, 'Estamos guardando...');
    let msg = "";
    try {
      if (data.id) {
        await SectionService.update(data, data.id);
        msg = "Sección actualizada correctamente";
      } else {
        await SectionService.create(data);
        msg = "Sección guardada correctamente";
      }
      getSections(1, limit);
      blockUI.current.open(false);
      alertUI.current.open(true, msg, 'success');
      setActiveTab(1);
      resetSection();
    } catch (error) {
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
      console.log(error);
    }
  };

  const changeLimit = (lim) => {
    setPage(1);
    setLimit(lim);
  };
  const changePage = (p) => {
    setPage(p);
  };

  const getData = (data, action) => {
    setSection(data);
    if (action === "edit") {
      setActiveTab(2);
    } else {
      deleteSection(data);
    }
  };

  const resetSection = () => {
    setSection({
      id: "",
      code: "",
      name: "",
      gradeId: "",
    });
  };

  const deleteSection = (data) => {
    Swal.fire({
      title: "Estas Seguro?",
      text: "No podrás recuperar este registro eliminado!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#333",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        blockUI.current.open(true, 'Estamos eliminando...');
        try {
          const response = await SectionService.delete(data.id);
          if (response) {
            getSections(1, limit);
            blockUI.current.open(false);
            alertUI.current.open(true, 'Sección eliminada correctamente', 'success');
            resetSection();
          }
        } catch (error) {
          blockUI.current.open(false);
          alertUI.current.open(true, 'Error', 'danger');
          console.log(error);
        }
      }
    });
  };

  const openTabAdd = () => {
    resetSection();
    setActiveTab(2);
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Secciones</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {props.user.school ? props.user.school : 'YAKHANA'}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Secciones
                </li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setActiveTab(1)}
                >
                  Listado
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => openTabAdd()}
                >
                  Agregar
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className={classnames(["fade show"])}>
              <SectionTable
                data={sections}
                pagination={pagination}
                changePage={changePage}
                changeLimit={changeLimit}
                getData={getData}
                isLoading={loading}
              />
            </TabPane>
            <TabPane tabId={2} className={classnames(["fade show"])}>
              <SectionForm
                getSectionData={getSectionData}
                grades={grades}
                sectionData={section}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(SectionPage);
