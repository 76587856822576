const Student = {
    dni: '',
    firstName: '',
    lastName: '',
    surname: '',
    birthdate: '',
    gender: '',
    phone: '33333333',
    email: '',
    address: '',
    role: '',
    campusId: null,
    status: 1
  }

  export default Student;
