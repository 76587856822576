import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from "classnames";
import { useUI, usePageUI } from '../app/context/ui';
import ProductService from '../services/ProductService';
import ProductTable from '../tables/ProductTable';
import ProductForm from '../forms/ProductForm';
import Product from '../models/Product';
import { connect } from "react-redux";
import Swal from "sweetalert2";

const ProductPage = (props) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [products, setProducts] = useState([]);
  const [productData, setProductData] = useState(Product);
  const [limit, setLimit] = useState(25);

  useEffect(() => {
    ProductService.init(props.user.accessToken, props.campus.id);
  }, [props.user, props.campus]);

  useEffect(() => {
    (async function getRecords() {
      await getProducts(limit);
    })();
  }, [limit]);

  const getProducts = async (l) => {
    setLoading(true);
    try {
      const response = await ProductService.list(l);
      setProducts(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getProductData = async (data) => {
    blockUI.current.open(true, 'Estamos guardando...');
    let response = "";
    try {
      if (data.id) {
        response = await ProductService.update(data, data.id);
      } else {
        response = await ProductService.create(data);
      }
      setActiveTab(1);
      resetProduct();
      setIsValid(true);
      getProducts(limit);
      blockUI.current.open(false);
      alertUI.current.open(true, response.data.message, 'success');
    } catch (error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
    }
  };

  const getData = (data, action) => {
    
    setProductData(data);
    if (action === "edit") {
      setActiveTab(2);
    } else {
      deleteProduct(data);
    }
  };

  const resetProduct = () => {
    setProductData(Product);
  };

  const deleteProduct = (data) => {
    Swal.fire({
      title: "Estas Seguro?",
      text: "No podrás recuperar este registro eliminado!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#333",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        blockUI.current.open(true, 'Estamos eliminando...');
        try {
          const response = await ProductService.delete(data.id);
          if (response) {
            getProducts(limit);
            blockUI.current.open(false);
            alertUI.current.open(true, response.data.message, 'success');
          }
        } catch (error) {
          console.log(error);
          blockUI.current.open(false);
          alertUI.current.open(true, 'Error', 'danger');
        }
      }
    });
  };

  const openTabAdd = () => {
    resetProduct();
    setIsValid(false);
    setActiveTab(2);
  };

  const getLimit = (l) => {
    setLimit(l)
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Productos</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {props.user.school ? props.user.school : 'YAKHANA'}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Productos
                </li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setActiveTab(1)}
                >
                  Listado
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => openTabAdd()}
                >
                  Agregar
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className={classnames(["fade show"])}>
              <ProductTable
                isLoading={loading}
                data={products}
                getData={getData}
                getLimit={getLimit}
              />
            </TabPane>
            <TabPane tabId={2} className={classnames(["fade show"])}>
              <ProductForm
                getProductData={getProductData}
                productData={productData}
                isValid={isValid}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(ProductPage);
