import { useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  TableContainer,
  Paper,
} from "@mui/material";
import { upperCase } from "lodash";
import {useHistory } from 'react-router-dom';
import AppHelper from "../../helpers/AppHelper";
import dayjs from "dayjs";

export const TaskListResults = ({
  customers,
  tasks,
  changePage,
  changeLimit,
  count,
  openModalEdit,
  downloadDocument,
  campus,
  ...rest
}) => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    changeLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    changePage(newPage + 1);
  };

  return (
    <>
      <Card {...rest}>
        <TableContainer component={Paper}>
          <Table sx={{ maxHeight: 440 }}>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Sección</TableCell>
                <TableCell>Sede</TableCell>
                <TableCell>Solicitud</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks?.map((task) => (
                <TableRow
                  hover
                  key={task.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell padding="checkbox">{task.id}</TableCell>
                  <TableCell>{upperCase(task.settings.section.name)}</TableCell>
                  <TableCell>{upperCase(task.settings.campus.name)}</TableCell>
                  <TableCell>{upperCase(task.settings.reportCard.format)}</TableCell>
                  <TableCell>{dayjs(task.createdAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                  <TableCell>
                    <span className={AppHelper.statusLabelTask(task.status).class}>
                      {AppHelper.statusLabelTask(task.status).name}
                    </span>
                  </TableCell>
                  <TableCell>
                    {
                       task.status === 'completed' && campus.id === task.settings.campus.id &&  (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => downloadDocument(task.id)}
                        >
                          Descargar Archivo
                        </Button>
                       )
                    }
                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </Card>
    </>
  );
};
