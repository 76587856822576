import React, {useState, useEffect} from 'react'
import Pagination from 'react-js-pagination';
import Spinner from '../components/Shared/Spinner';
import UserService from '../services/UserService';
import { connect } from 'react-redux';

let option = {}
const title = (s) => {
  switch(s) {
    case 'student' : option = {
              class: 'tag tag-success',
              label: 'Alumno'
            };
            break;
    case 'agent' : option = {
              class: 'tag tag-info',
              label: 'Apoderado'
            };
            break;
    case 'manager' : option = {
              class: 'tag tag-warning',
              label: 'Administrador'
            };
            break;
    case 'super-admin' : option = {
              class: 'tag tag-primary',
              label: 'Admin'
            };
            break;   

    case 'secretary': option = {
              class: 'tag tag-danger',
              label: 'Secretaria'
            };
            break;
  }
  return option;
}

const UsersAllTable = (props) => {
  const [search, setSearch] = useState('')
  const [users, setUsers] = useState([]);

  const handlerChangeSearch = e => {
    setSearch(e.target.value)
  }

  const getUsers = async (filter) => {
    try {

      const response = await UserService.searchUsers(filter)
      setUsers(response.data.data)

    } catch(e) {
      console.log(e);
    }
  }

  useEffect(() => {
    UserService.init(props.user.accessToken);
  }, []);

  useEffect(() => {
    if (search.length > 3) {
      getUsers(search);
    }
  }, [search]);

  return (
    <>
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="input-group">
              <input
                type="search"
                className="form-control"
                onChange={handlerChangeSearch}
                placeholder="Buscar por DNI o correo electrónico"
                value={search} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="table-responsive card">
      <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
        <thead>
          <tr>
            <th>Imagen</th>
            <th>DNI</th>
            <th>Apellido paterno</th>
            <th>Apellido materno</th>
            <th>nombres</th>
            <th>Email</th>
            <th>Tipo</th>
          </tr>
        </thead>
        <tbody>
          { !!users &&
            users.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="w60">
                    <img className="avatar" src={`../assets/images/xs/avatar${item.gender === 'M' ? 2 : 1}.jpg`} alt="" />
                  </td>
                  <td>{ item.dni }</td>
                  <td>{ item.lastName }</td>
                  <td>{ item.surname }</td>
                  <td>{ item.firstName }</td>
                  <td>{ item.email}</td>
                  <td>{ title(item.role).label }</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    user: state.user,
    campus: state.campus
  };
};


export default connect(mapStateToProps)(UsersAllTable);
