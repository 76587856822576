import {environment} from '../environments';
import {isEmpty, isNumber} from 'lodash';
import axios from 'axios';

class AppService {

  constructor() {

    this.http = axios.create({
      baseURL: environment.apiMain,
      baseURL2: environment.apiAcademy
    });

    this.http.defaults.params = {};

    /* this.http.interceptors.request.use((req) => {
      console.log(req);
      console.log(this.campus);
      return req;
    }, error => {
      return Promise.reject(error)
    });

    this.http.interceptors.response.use(
      res => {
        return res;
      },
      err => {
        return Promise.reject(err);
      }
    ); */
  }

  init(accessToken = '', campus = 0) {
    this.setAccessToken(accessToken);
    this.setCampus(campus);
  }

  setCampus(campus) {
    if (isNumber(campus) && campus > 0) {
      this.http.defaults.params['campus_id'] = campus;
    } else {
      delete this.http.defaults.params['campus_id'];
    }
  }

  setAccessToken(accessToken) {
    if (!isEmpty(accessToken)) {
      this.http.defaults.headers.common['Authorization'] = 'bearer ' + accessToken;
      this.http.get('/cms/validate-token')
      .catch(function (error) {
        window.location.href = '/login';
      });
    } else {
      delete this.http.defaults.headers.common['Authorization'];
    }
  }

  reset() {
    delete this.http.defaults.headers.common['Authorization'];
    this.http.defaults.params = {};
  }

  getOptions() {
    return this.http.defaults.headers;
  }
}

export default AppService;
