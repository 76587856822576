import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from 'classnames';
import UserService from '../services/UserService';
import CreatePage from './student/createPage';
import EditPage from './student/editPage';
import AgentForm from '../forms/AgentForm';
import StudentService from '../services/StudentService';
import Agent from '../models/Agent';
import Student from '../models/Student';
import ListPage from './student/listPage';
import { connect } from 'react-redux';
import {useUI, usePageUI} from '../app/context/ui';
import {pick, keys} from 'lodash';
import AppHelper from '../helpers/AppHelper';

 const StudentPage = (props) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();

  const [activeTab, setActiveTab] = useState(1);
  const [isEdit, setIsEdit] = useState(false)
  const [agents, setAgents] = useState([]);
  const [agentData, setAgentData] = useState({});
  const [studentData, setStudentData] = useState({});
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    StudentService.init(props.user.accessToken, props.campus.id);
    UserService.init(props.user.accessToken, props.campus.id);
    setAgentData(Agent);
  }, [props.campus, props.user])

  const searchAgent = async(code) => {
    blockUI.current.open(true, 'Estamos buscando...');
    try {
      const response = await UserService.searchAgent(code);
      if(response.data.length) {
        setAgents(response.data[0]);
        blockUI.current.open(false);
      } else {
        blockUI.current.open(false);
        Swal.fire({
          title: 'No se encontró el apoderado',
          html: 'Deseas agregarlo?',
          type: 'error',
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: '#17a2b8',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText:"No"
        }).then((result) => {
          if (result.value) {
            changeTab(3);
          }
        })
      }
    } catch (error) {
      blockUI.current.open(false);
    }
  }

  const saveAgent = async (agent) => {
    blockUI.current.open(true, 'Estamos guardando...');
    try {
      const response = await UserService.create(agent)
      if (response) {
        blockUI.current.open(false);
        alertUI.current.open(true, 'Apoderado Guardado', 'success');
        setActiveTab(2);
      }
    } catch (error) {
      console.log(error);
      const er = error.response.data.errors;
      blockUI.current.open(false);
      alertUI.current.open(true, AppHelper.showErrors(er), 'danger');
    }
  }

  const changeTab = val => {
    setActiveTab(val);
    if (val === 1) {
      setIsLoad(true);
    }
  }

  const getStudent = (val, action) => {
    if( action === 'edit') {
      readStudent(val.id);
      setActiveTab(2)
    }
  }

  const readStudent = async(id) => {
    blockUI.current.open(true, 'Estamos cargando el alumno....');
    try {
      const response = await StudentService.read(id);
      if (response) {
        blockUI.current.open(false);
        const std = response.data;
        if (std.agents.length > 0) {
          const agentCurrent = std.agents.pop();
          std.agentFullName = agentCurrent.fullName;
          std.agentId = agentCurrent.fullName.id;
          std.agentRelationship = agentCurrent.pivot.relationship;
          std.agents = agentCurrent;
        }

        std.email = std.email ? std.email : '';
        std.phone = std.phone ? std.phone : '';
        std.dni = std.dni ? std.dni : '';
        std.schoolFrom = std.schoolFrom ? std.schoolFrom : '';

        let initialValues = pick(std, keys(Student));
        setStudentData(initialValues);
        setIsEdit(true);
      }
    } catch (error) {
      console.log(error);
      const er = error.response.data.errors;
      alertUI.current.open(true, AppHelper.showErrors(er), 'danger');
    }
  }

  const openTabAdd = () => {
    setStudentData(Student);
    setIsEdit(false);
    setActiveTab(2);
  };


	return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Alumnos</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">{props.user.school ? props.user.school : 'YAKHANA'}</li>
                <li className="breadcrumb-item active" aria-current="page">Alumnos</li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setActiveTab(1)}
                >
                  Listado
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => openTabAdd()}
                >
                  Agregar Alumno
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 3 })}
                  onClick={() => setActiveTab(3)}
                >
                  Agregar Apoderado
                </NavLink>
              </NavItem>
            </Nav>

          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className={classnames(['fade show'])}>
              <ListPage getStudent={getStudent} isLoad={isLoad} />
            </TabPane>
            <TabPane tabId={2} className={classnames(['fade show'])}>
              {
                isEdit ? 
                <EditPage 
                  studentData={studentData}
                  changeTab={changeTab}
                  agents={agents}
                  searchAgent={searchAgent}
                /> 
                : 
                <CreatePage
                  changeTab={changeTab}
                  agents={agents}
                  searchAgent={searchAgent}
                  studentData={studentData}
                />
              }
            </TabPane>
            <TabPane tabId={3} className={classnames(['fade show'])}>
              <AgentForm
                saveAgent={saveAgent}
                searchAgent={searchAgent}
                agentData = {agentData}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );

}

const mapStateToProps = state => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(StudentPage);
