import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from "classnames";
import CampusService from '../services/CampusService';
// import ReportDashboardTable from '../tables/ReportDashboardTable';
import LastEnrollsTable from '../tables/LastEnrollsTable';
import Campus from '../models/Campus';
import { connect } from "react-redux";
import { useUI, usePageUI } from '../app/context/ui';
// import ManagementReport from '../components/university/Dashboard/Dashboard'
import Swal from "sweetalert2";

const DashboardPage = (props) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [activeTab, setActiveTab] = useState(3);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [campuses, setCampuses] = useState([]);
  const [limit, setLimit] = useState(0)
  const [campusData, setCampusData] = useState({});

  useEffect(() => {
    CampusService.init(props.user.accessToken)
    setCampusData(Campus);
  }, [props.user]);

  useEffect(() => {
    (async function getRecords() {
      await getCampuses(limit);
    })();
  }, [limit]);

  const getCampuses = async (l) => { 
    setLoading(true);
    try {
      const response = await CampusService.list();
      setCampuses( response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCampusData = async(data) => {
    blockUI.current.open(true, 'Estamos guardando...');
    let response = "";
    try {
      if (data.id) {
        response = await CampusService.update(data, data.id);
      } else {
        response = await CampusService.create(data);
      }
      setActiveTab(1);
      resetSection();
      setIsValid(true);
      getCampuses();
      blockUI.current.open(false);
      alertUI.current.open(true, response.data.message, 'success');
    } catch (error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
    }
  };

  const getData = (data, action) => {
    
    setCampusData(data);
    if (action === "edit") {
      setActiveTab(2);
    } else {
      deleteSection(data);
    }
  };

  const resetSection = () => {
    setCampusData(Campus);
  };

  const deleteSection = (data) => {
    
    Swal.fire({
      title: "Estas Seguro?",
      text: "No podrás recuperar este registro eliminado!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#333",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        blockUI.current.open(true, 'Estamos eliminando...');
        try {
          const response = await CampusService.delete(data.id);
          if (response) {
            getCampuses();
            blockUI.current.open(false);
            alertUI.current.open(true, response.data.message, 'success');
          }
        } catch (error) {
          console.log(error);
          blockUI.current.open(false);
          alertUI.current.open(true, 'Error', 'danger');
        }
      }
    });
  };

  const openTabAdd = () => {
    resetSection();
    setIsValid(false);
    setActiveTab(2);
  };

  const openTabLastEnrolls = () => {
    resetSection();
    setIsValid(false);
    setActiveTab(3);
  };

  const getLimit = (l) => {
    setLimit(l)
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Reportes</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {props.user.school ? props.user.school : 'YAKHANA'}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Reportes
                </li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              {/* <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setActiveTab(1)}
                >
                  Resumen
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => openTabAdd()}
                >
                  Reportes
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 3 })}
                  onClick={() => openTabLastEnrolls()}
                >
                  Ultimas Matrículas
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            {/* <TabPane tabId={1} className={classnames(["fade show"])}>
              <ManagementReport />
            </TabPane>
            <TabPane tabId={2} className={classnames(["fade show"])}>
              <ReportDashboardTable />
            </TabPane> */}
            <TabPane tabId={3} className={classnames(["fade show"])}>
              <LastEnrollsTable />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(DashboardPage);
