import React, { useState, useEffect } from "react";
import UserService from "../services/UserService";
import AcademyService from "../services/AcademyService";
import { connect } from "react-redux";
import NoteTable from "../components/note/note-table";
import NoteFilter from "../components/note/note-filter";
import { addFilterAcademy } from "../redux/actions/filters";

const NotesPage = ({ user, campus, filters, dispatch }) => {
  const academyService = new AcademyService();
  const [year, setYear] = useState("");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [sections, setSections] = useState([]);
  const [courses, setCourses] = useState([]);
  const [filter, setFilter] = useState(null);

  const listNotes = async (data) => {
    try {
      const response = await academyService.listCards(data);
      setRows(response.data.rows);
      const cols = response.data.header;
      const headers = [];
      cols.map((col) => {
        col.preProcessEditCellProps = (params) => {
          let hasError = '';
          if (data.courseId === 38) {
            hasError = params.props.value < 0 || params.props.value > 2000;
          } else {
            hasError = params.props.value < 0 || params.props.value > 20;
          }
          return { ...params.props, error: hasError };
        };
        headers.push(col);
      })
      setColumns(headers);
      setFilter(data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateNote = async (data) => {
    try {
      data.courseId = filter.courseId;
      data.periodId = filter.periodId;
      await academyService.updateCard(data);
    } catch (error) {
      console.log(error);
    }
  };

  const listSections = async (yearId) => {
    setYear(yearId);
    try {
      const response = await UserService.listSectionsByCampus(
        yearId,
        campus.id
      );
      setSections(response.data);
    } catch (error) {}
  };

  const listCourses = async (sectionId) => {
    try {
      const response = await UserService.listCoursesByYear(year, sectionId);
      setCourses(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    UserService.init(user.accessToken, campus.id);
    // listNotes(filters.filterAcademy);
  }, [campus, user]);

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Registros</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {user.school ? user.school : "YAKHANA"}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Registros
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <NoteFilter
            listSections={listSections}
            sections={sections}
            listCourses={listCourses}
            courses={courses}
            listNotes={listNotes}
            campus={campus}
            filters={filters}
            dispatch={dispatch}
          />
          <NoteTable rows={rows} updateNote={updateNote} columns={columns} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus,
    filters: state.filters
  };
};

export default connect(mapStateToProps)(NotesPage);
