import { useState } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Stack,
  TableContainer,
  Paper,
} from "@mui/material";
import { upperCase } from "lodash";
import {useHistory } from 'react-router-dom';

export const TeacherListResults = ({
  customers,
  changePage,
  changeLimit,
  count,
  openModalEdit,
  ...rest
}) => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const history = useHistory();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    changeLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    changePage(newPage + 1);
  };

  return (
    <>
      <Card {...rest}>
        <TableContainer component={Paper}>
          <Table sx={{ maxHeight: 440 }}>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Nombre y Apellidos</TableCell>
                <TableCell>DNI</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Operaciones</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers?.map((customer) => (
                <TableRow
                  hover
                  key={customer.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell padding="checkbox">{customer.id}</TableCell>
                  <TableCell>{upperCase(customer.fullName)}</TableCell>
                  <TableCell>{customer.dni}</TableCell>
                  <TableCell>{customer.status}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => history.push(`/teachers/${customer.id}/courses`, {teacher: customer})}
                    >
                      Cursos
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="contained"
                        color="info"
                        onClick={() => openModalEdit(customer)}
                      >
                        Editar
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </Card>
    </>
  );
};
