import { Formik, Form } from "formik";
import * as yup from "yup";
import FilterAcademy from "../Filters/FilterAcademy";
import { addFilterAcademy } from "../../redux/actions/filters";
import { values } from "lodash";

const validationSchema = yup.object({
  yearId: yup
    .number("Elija el año lectivo")
    .required("El año lectivo es obligatorio"),
  sectionId: yup.number("Elija la sección").required("La sección es obligaria"),
  courseId: yup.number("Elija el Curso").required("El curso es obligatorio"),
  periodId: yup
    .number("Elija el Bimestre")
    .required("El bimestre es obligatorio"),
});

const initialValues = {
  yearId: "",
  sectionId: "",
  courseId: "",
  periodId: "",
};

const NoteFilter = ({
  listSections,
  listCourses,
  courses,
  sections,
  listNotes,
  campus,
  filters,
  dispatch
}, props) => {
  return (
    <div className="card">
      <div className="card-body">
        <Formik
          initialValues={filters.filterAcademy}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            values.campusId = campus.id;
            dispatch(addFilterAcademy(values));
            listNotes(values);
          }}
          enableReinitialize={true}
        >
          {(props) => (
            <Form>
              <FilterAcademy
                {...props}
                listSections={listSections}
                sections={sections}
                listCourses={listCourses}
                courses={courses}
                values={filters.filterAcademy}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NoteFilter;
