
import React, {useState, useEffect} from 'react';
import { Modal, ModalHeader, ModalBody} from "reactstrap";

const StudentAgentSearchModal = (props) => {
  const isOpen = props.isOpen;
  const [show, setShow] = useState(true);
  const [search, setSearch] = useState('');
  const toggle = () => {
    props.changeOpenModal(!show);
    setShow(!show);
  };
  const {className} = props;

  useEffect(() => {
    setSearch('')
    setShow(props.isOpen);
  }, [props.isOpen])

  const handlerChangeSearch = e => {
    setSearch(e.target.value);
    if(e.target.value.length === 8) {
      props.changeOpenModal(!show);
      props.changeSearch(e.target.value);
    }
  }

  return(
    <>
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Buscar Apoderado</ModalHeader>
        <ModalBody>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="input-group">
                    <input type="search" className="form-control" onChange={handlerChangeSearch} placeholder="ingrese el DNI" value={search} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default StudentAgentSearchModal;