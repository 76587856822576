import {types} from '../types';

export function addCampus(payload) {
  return {
    type: types.CAMPUS_ADD,
    payload,
  };
}

export function updateCampus(payload) {
  return {
    type: types.CAMPUS_UPDATE,
    payload,
  };
}

export function removeCampus() {
    return {
      type: types.CAMPUS_REMOVE,
      payload: {},
    };
  }