import React, { useState, useEffect} from 'react';
import { Modal, ModalHeader, ModalBody} from "reactstrap";
import { connect } from 'react-redux';
import StudentService from '../../services/StudentService';
import Pagination from 'react-js-pagination';
import Spinner from '../Shared/Spinner';

const StudentSearchModal = ({ isOpen, className, changeOpenModal, user, campus, selectEstudent  } ) => {
  const [student, setStudent] = useState([]);
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    activePage: 1,
    itemsCountPerpage: 1,
    totalItemsCount: 1
  });
  const toggle = () => {
    changeOpenModal(!show);
    setShow(!show);
  };

  useEffect(() => {
    StudentService.init(user.accessToken, campus.id);
  }, [campus])

  useEffect(() => {
    (async function getRecords() {
      await getStudents(page, 10, search);
    })();
  }, [page, search])

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen])

  const getStudents = async (p, lim, fil) => {
    setLoading(true)
    try {
      const response = await StudentService.list(p,lim,fil)
      setStudent(response.data.data)
      setPagination({
        activePage: response.data.currentPage,
        itemsCountPerpage: response.data.perPage,
        totalItemsCount: response.data.total,
        lastPage: response.data.lastPage
      });
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  const handlerChangeSearch = e => {
    setSearch(e.target.value);
  }
  const handlePageChange = page => {
    setPage(page);
  }

  const selectEstudentItem = item => {
    selectEstudent(item);
  }

  return(
    <>
      <Modal isOpen={isOpen} toggle={toggle}  size="lg" className={className}>
        <ModalHeader toggle={toggle}>Alumnos</ModalHeader>
        <ModalBody>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="input-group">
                    <input type="search" className="form-control" onChange={handlerChangeSearch} placeholder="Buscar" value={search} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive card">
            <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
              <thead>
                <tr>
                  <th>Código</th>
                  <th>DNI</th>
                  <th>Apellidos y Nombres</th>
                </tr>
              </thead>
              <tbody>
                {
                  student.map((item, index) => {
                    return (
                      <tr key={index} onClick={() => selectEstudentItem(item)}>
                        <td>{ item.code }</td>
                        <td>{ item.dni }</td>
                        <td>{ item.fullName }</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            {
              loading && <Spinner />
            }
            {
              pagination.lastPage > 1 &&
              <div className="d-flex justify-content-center">
                <Pagination
                  activePage={pagination.activePage}
                  itemsCountPerPage={parseInt(pagination.itemsCountPerPage)}
                  totalItemsCount={pagination.totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClass='page-item '
                  linkClass='page-link'
                />
              </div>
            }
          </div>
        </ModalBody>
      </Modal>
    </>
  )

}

const mapStateToProps = state => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(StudentSearchModal);