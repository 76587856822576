import React, {useState, useEffect, useReducer} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from 'classnames';
import EnrollForm from '../forms/EnrollForm';
import StudentService from '../services/StudentService';
import SchoolYearService from '../services/SchoolYearService';
import SharedService from '../services/SharedService';
import EnrollService from '../services/EnrollService';
import {connect} from 'react-redux';
import {useUI, usePageUI} from '../app/context/ui';
import Moment from 'moment';

const EnrollEditPage = (props) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const location = useLocation();
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(2);
  const {enroll} = location.state;
  const [student, setStudent] = useState([]);
  const [schoolYear, setSchoolYear] = useState([]);
  const [levels, setLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [sections, setSections] = useState([]);
  const [filter, setFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [isValid, setIsValid] = useState(false);
  const [pagination, setPagination] = useState({
    activePage: 1,
    itemsCountPerpage: 1,
    totalItemsCount: 1
  });
  
  useEffect(() => {
    // Init Services
    EnrollService.init(props.user.accessToken, props.campus.id);
    StudentService.init(props.user.accessToken, props.campus.id);
    SharedService.init(props.user.accessToken, props.campus.id);
    SchoolYearService.init(props.user.accessToken, props.campus.id);
    // BI
    (async function getRecords() {
      await getSchoolYear();
      await getlevels();
      await getGrades(enroll.levelId);
      await getSections(enroll.gradeId, enroll.schoolYearId);
      //await setEnroll();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.campus]);

  useEffect(() => {
    
    (async function getRecords() {
      await getStudents(page, 10, filter);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter, props.campus]);

  const getStudents = async (p, lim, fil) => {
    try {
      const r = await StudentService.list(p,lim,fil);
      setStudent(r.data.data)
      setPagination({
        activePage: r.data['currentPage'],
        itemsCountPerpage: r.data['currentPage'],
        totalItemsCount: r.data['total']
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getSchoolYear = async () => {
    try {
      const response = await SchoolYearService.list();
      setSchoolYear(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getlevels = async () => {
    try {
      const response = await SharedService.listlevels();
      setLevels(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getGrades = async (levelId) => {
    try {
      const response = await SharedService.listGradesByLevel(levelId);
      setGrades(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getSections = async (gradeId, yearId) => {
    try {
      const response = await SharedService.listSectionsByGrade(gradeId, yearId);
      setSections(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const changePage = p => {
    setPage(p)
  }

  const changeSearch = value => {
    setFilter(value)
  }

  const changeGrades = async (levelId) => {
    console.log('X')
    await getGrades(levelId);
  }
  const changeSections = async (gradeId, yearId) => {
    console.log('Y')
    await getSections(gradeId, yearId);
  }

  const saveEnroll = async (data) => {
    console.log(data);
    blockUI.current.open(true, 'Estamos guardando...');
    try {
      await EnrollService.update(data, data.id);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Matrícula actualizada correctamente', 'success');
      history.push('/enrolls');
      setIsValid(true);
    } catch (error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
    }
  }

  const openTabAdd = () => {
    //setEnroll(Enroll);
    setIsValid(false);
    setActiveTab(2);
  };

	return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Matrícula</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">{props.user.school ? props.user.school : 'YAKHANA'}</li>
                <li className="breadcrumb-item active" aria-current="page">Edicion de Matrícula</li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => history.push('/enrolls')}
                >
                  Listado
                        </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => openTabAdd()}
                >
                  Agregar
                        </NavLink>
              </NavItem>
            </Nav>

          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className={classnames(['fade show'])}>

            </TabPane>
            <TabPane tabId={2} className={classnames(['fade show'])}>
              <EnrollForm
                enroll={enroll}
                data={student}
                pagination={pagination}
                changePage={changePage}
                changeSearch={changeSearch}
                schoolYear={schoolYear}
                levels={levels}
                grades={grades}
                sections={sections}
                changeGrades={changeGrades}
                changeSections={changeSections}
                user={props.user}
                getEnroll={saveEnroll}
                isValid={isValid}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    campus: state.campus,
    filters: state.filters
  };
};

export default connect(mapStateToProps)(EnrollEditPage);
