
import React, {useState, useEffect} from 'react';
import { Modal, ModalHeader, ModalBody} from "reactstrap";
import Pagination from 'react-js-pagination';
import Spinner from '../../Shared/Spinner';

const EnrollStudentsModal = (props) => {
  const isOpen = props.isOpen;
  const [show, setShow] = useState(true);
  const [search, setSearch] = useState('');
  const toggle = () => {
    props.changeOpenModal(!show);
    setShow(!show);
  };
  const {className, pagination} = props;
  const data = props.data;

  useEffect(() => {
    setShow(props.isOpen);
  }, [props.isOpen])

  const handlerChangeSearch = e => {
    setSearch(e.target.value);
    props.changeSearch(e.target.value);
  }

  const handlePageChange = page => {
    props.changePage(page);
  }

  const selectEstudent = item => {
    props.selectEstudent(item);
  }

  return(
    <>
      <Modal isOpen={isOpen} toggle={toggle}  size="lg" className={className}>
        <ModalHeader toggle={toggle}>Alumnos</ModalHeader>
        <ModalBody>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="input-group">
                    <input type="search" className="form-control" onChange={handlerChangeSearch} placeholder="Buscar" value={search} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive card">
            <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
              <thead>
                <tr>
                  <th>Código</th>
                  <th>DNI</th>
                  <th>Apellidos y Nombres</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.map((item, index) => {
                    return (
                      <tr key={index} onClick={() => selectEstudent(item)}>
                        <td>{ item.code }</td>
                        <td>{ item.dni }</td>
                        <td>{ item.fullName }</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            {
              data.length === 0 && <Spinner />
            }
            {
              data.length > 0 &&
              <div className="d-flex justify-content-center">
                <Pagination
                  activePage={pagination.activePage}
                  itemsCountPerPage={parseInt(pagination.itemsCountPerPage)}
                  totalItemsCount={pagination.totalItemsCount}
                  pageRangeDisplayed={parseInt(pagination.lastPage) < 5 ? parseInt(pagination.lastPage) : 5}
                  onChange={handlePageChange}
                  itemClass='page-item '
                  linkClass='page-link'
                />
              </div>
            }
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EnrollStudentsModal;
