import {
  Box,
  Grid,
  TextField
} from "@mui/material";

export const TeacherForm = (props) => {
  const { values, errors, handleChange, handleBlur, touched, searchDni } =
    props;

    const handleChangeDni = (event) => {
      if(event.target.value.length > 7) {
        searchDni(event.target.value);
      }
      handleChange(event);
    }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} mt={0.5}>
        <Grid item lg={6} md={6} xs={12}>
          <TextField
            name="dni"
            value={values.dni}
            placeholder="DNI"
            fullWidth
            onChange={handleChangeDni}
            onBlur={handleBlur}
            error={touched.dni && Boolean(errors.dni)}
            helperText={touched.dni && errors.dni}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <TextField
            name="firstName"
            value={values.firstName}
            placeholder="Nombres"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.firstName && Boolean(errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <TextField
            name="lastName"
            value={values.lastName}
            placeholder="Apellido Paterno"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.lastName && Boolean(errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <TextField
            name="surname"
            value={values.surname}
            placeholder="Apellido Materno"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.surname && Boolean(errors.surname)}
            helperText={touched.surname && errors.surname}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            name="fullName"
            value={values.fullName}
            placeholder="Nombres y apellidos"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.fullName && Boolean(errors.fullName)}
            helperText={touched.fullName && errors.fullName}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
