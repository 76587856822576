import AppService from "./AppService";

class CampusService extends AppService {
  list(limit = 0) {
    return this.http.get(`/campuses?limit=${limit}`);
  }

  create(data) {
    return this.http.post("/campuses", data);
  }

  update(data, id) {
    return this.http.patch(`/campuses/${id}`, data);
  }
  delete(id) {
    return this.http.delete(`/campuses/${id}`);
  }
}

export default new CampusService();
