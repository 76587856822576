import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { useUI, usePageUI } from '../app/context/ui';
import ReportService from '../services/ReportService';
import SharedService from '../services/SharedService';
import SchoolYearService from '../services/SchoolYearService';
import Spinner from '../components/Shared/Spinner';
import Pagination from 'react-js-pagination';
import FiltersByStatus from '../components/Filters/FiltersByStatus';
import Moment from 'moment';

const LastEnrollsTable = (props) => {
  Moment.locale('es');
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sum, setSum] = useState(0);
  const [showBtn, setShowBtn] = useState(false);
  const [filter, setFilter] = useState('');
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(
    {
      activePage: 1,
      itemsCountPerpage: 1,
      totalItemsCount: 1,
      lastPage: 1
    },
  )

  const getReport = async(page, filter) => {
    setLoading(true);
    try{
      const response = await ReportService.lastEnrolls(page, filter);
      setData(response.data.data.data);
      setSum(response.data.total);
      setPagination({
        activePage : response.data.data.currentPage,
        itemsCountPerpage : response.data.data.perPage,
        totalItemsCount : response.data.data.total,
        lastPage : response.data.data.lastPage
      });
      setLoading(false);
    } catch(error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
      setLoading(false);
    }
  }

  const handlePageChange = page => {
    setPage(page);
  }

  const getReportEnroll = async() => {
    blockUI.current.open(true, 'Estamos buscando...');
    try{
      const response = await ReportService.lastEnrollsReport(filter, 0);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      file.download = `reporte-de-matricula-${Moment().format('YYYY-MM-DD-H-m-s')}.xlsx`;
      file.click();
      blockUI.current.open(false);
    } catch(error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
      setLoading(false);
    }
  }


  const onFilter = (fil) => {
    setFilter(fil);
    getReport(page, fil);
    setShowBtn(true);
  }

  useEffect(() => {
    ReportService.init(props.user.accessToken, props.campus.id);
    SharedService.init(props.user.accessToken, props.campus.id);
    SchoolYearService.init(props.user.accessToken, props.campus.id);
  }, [props.campus]);

  useEffect(() => {
      getReport(page, filter);
    
  }, [page]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Reporte</h3>
          <div className="card-options">
            {
              showBtn &&
              <button className="center btn btn-primary" onClick={getReportEnroll}>
                Exportar a excel
              </button>
            }
          </div>
        </div>
        <div className="card-body">
          <FiltersByStatus setFilter={onFilter}  />
        </div>
      </div>
      <div className="table-responsive card">
        <div className="text-right">
                <h1 className="page-title">Total: {pagination.totalItemsCount} | Monto: S/ {sum}</h1>
        </div>
      </div>
      <div className="table-responsive card"> 
        <table id="emp" className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
          <thead>
            <tr>
              <th>DNI</th>
              <th>Apellidos y Nombres</th>
              <th>Año</th>
              <th>Sección</th>
              <th>Matrícula</th>
              <th>Pensión</th>
              <th>Tipo</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {
              data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.userDni}</td>
                    <td>{item.userFullName}</td>
                    <td>{item.schoolYearName}</td>
                    <td>{item.sectionName}</td>
                    <td>S/ {item.total ? item.total : item.enrollAmount}</td>
                    <td>S/ {item.pensionAmount}</td>
                    <td>{item.enrollType === 1 ? 'Virtual' : item.enrollType === 2 ? 'Semi Presencial' : 'Presencial'}</td>
                    <td>{Moment(item.createdAt).format('DD/MM/YYYY')}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        { loading && <Spinner />}
        { pagination.lastPage > 1 && <div className="d-flex justify-content-center">
          <Pagination
            activePage={pagination.activePage}
            itemsCountPerPage={pagination.itemsCountPerPage}
            totalItemsCount={pagination.totalItemsCount}
            pageRangeDisplayed={pagination.lastPage >= 5 ? 5 : pagination.lastPage}
            onChange={handlePageChange}
            itemClass='page-item '
            linkClass='page-link'
          />        
        </div> }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
    return {
      user: state.user,
      campus: state.campus
    };
  };
  
  export default connect(mapStateToProps)(LastEnrollsTable);
