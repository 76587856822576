/** @format */
import React, { useState } from 'react'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

const Dropdown = ({ buttonDropdownName, actionsArray, setClick }) => {
  const [dropdownOpen, setOpen] = useState(false)

  const toggle = () => setOpen(!dropdownOpen)

  const _onClick = (action, item) => {
    setClick(action, item);
  }

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} color="primary">
      <DropdownToggle caret color="primary">{buttonDropdownName}</DropdownToggle>
      <DropdownMenu>
        {actionsArray.map((action, index) => (
          <DropdownItem key={index} onClick={() => _onClick(action.function, action.data)}>{action.name}</DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default Dropdown;