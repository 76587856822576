import React from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';

const ProtectedRoute = (props) => {
    const Component = props.component;
    const {user, roles} = props;

    const authToken = user.accessToken;

    if (!authToken) {
      return <Redirect to='/login' />;
    }

    if (roles && !roles.includes(user.role)) {
      return <Redirect to='/notes' />;
    }

    return <Component {...props} />;

    // return authToken ? (
    //     <Component />
    // ) : (
    //     <Redirect to={{pathname: '/login'}} />
    // )
}


const mapStateToProps = state => {
    return {
      user: state.user
    };
  };
  
export default connect(mapStateToProps)(ProtectedRoute);