import {
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText
} from "@mui/material";

const yearList = [
  {value: 18, key:'2024'}
]

export const ProfileForm = (props) => {
  const { values, errors, sections, courses, handleChange, touched, searchSection, searchCourses } =
    props;

    const handleChangeYear = (event) => {
      searchSection(event.target.value);
      handleChange(event);
    }

    const handleChangeSection = (event) => {
      searchCourses(event.target.value);
      handleChange(event);
    }
    
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} mt={0.5}>
        <Grid item lg={12} md={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="school_year_id-label">Año Lectivo</InputLabel>
            <Select
              labelId="school_year_id-label"
              id="school_year_id"
              label="Año Lectivo"
              name="school_year_id"
              value={values.school_year_id}
              onChange={handleChangeYear}
              error={touched.school_year_id && Boolean(errors.school_year_id)}
            >
              <MenuItem value=''>Seleccione Año</MenuItem>
              <MenuItem value="18">2024</MenuItem>
              {/* <MenuItem value="15">2023</MenuItem> */}
            </Select>
            {touched.school_year_id && errors.school_year_id &&
              <FormHelperText sx={{ color: "#bf3333", marginLeft: "0px !important" }}>{errors.school_year_id }</FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="section_id-label">Sección</InputLabel>
            <Select
              labelId="section_id-label"
              id="section_id"
              label="Sección"
              name="section_id"
              value={values.section_id}
              onChange={handleChangeSection}
              error={touched.section_id && Boolean(errors.section_id)}
            >
              <MenuItem value=''>Seleccione Sección</MenuItem>
              {sections?.map((section) => (
                <MenuItem key={section.id} value={section.id}>{section.name}</MenuItem>
              ))}
              
            </Select>
            {touched.section_id && errors.section_id &&
              <FormHelperText sx={{ color: "#bf3333", marginLeft: "0px !important" }}>{errors.section_id }</FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="course_id-label">Curso</InputLabel>
            <Select
              labelId="course_id-label"
              id="course_id"
              label="Curso"
              name="course_id"
              value={values.course_id}
              onChange={handleChange}
              error={touched.course_id && Boolean(errors.course_id)}
            >
              <MenuItem value=''>Seleccione Curso</MenuItem>
              {
                courses?.map((course) => (
                  <MenuItem key={course.id} value={course.id}>{course.name}</MenuItem>
                ))
              }
              
            </Select>
            {touched.course_id && errors.course_id &&
              <FormHelperText sx={{ color: "#bf3333", marginLeft: "0px !important" }}>{errors.course_id }</FormHelperText>
            }
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
