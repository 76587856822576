import axios from "axios";

import {
  get as _get,
  has as _has,
  isEmpty as _isEmpty,
  isNull as _isNull,
} from "lodash";

class AcademyService {
  constructor() {
    this.http = axios.create({
      // baseURL: "http://api-local.colegiosmonserrat.info:7012/",
      baseURL: "https://api.colegiosmonserrat.info/",
    });
    this.http.defaults.params = {};
  }

  reset() {
    delete this.http.defaults.headers.common["Authorization"];
    this.http.defaults.params = {};
  }
  getOptions() {
    return this.http.defaults.headers;
  }

  listAreas() {
    return this.http.get("/areas");
  }

  listCards(data) {
    return this.http.post("/report-cards", data);
  }

  updateCard(data) {
    return this.http.patch("/report-cards", data);
  }

  listTasks(page) {
    return this.http.get(`/tasks?sort=-id&render=paginate&page=${page}`);
  }

  downloadOne(sectionId, reportCardType, enrollId, periodId) {
    return this.http.get(
      `/report-cards/report-for-student?type=report_card&section_id=${sectionId}
      &report_card_type=student&report_card_format=${reportCardType}&enroll_id=${enrollId}&
      period_id=${periodId}&ext=pdf`
    );
  }

  createTask(sectionId, reportCardType, periodId, campusId, yearId, userId) {
    return this.http.get(
      `/tasks/add-to-queue?type=report_card&section_id=${sectionId}&report_card_type=section&report_card_format=${reportCardType}&period_id=${periodId}&school_year_id=${yearId}&campus_id=${campusId}&owner_id=${userId}`,
      { responseType: "blob" }
    );
  }

  downloadGroup(data) {
    return this.http.post(
      "tasks/resource-url",
      data
      // { responseType: "blob"},
      // {headers: {
      //   Accept: 'application/pdf',
      //   ContentType: 'application/pdf'
      // },}
    );
  }
}

export default AcademyService;
