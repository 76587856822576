import AppService from "./AppService";

class ReportService extends AppService {
  list(page, filter) {
    return this.http.get(`/reports/payments?page=${page}${filter}`);
  }

  listAll(filter) {
    return this.http.get(`/reports/payments?render=all&${filter}&order_column=section_id&order_direction=ASC`);
  }

  lastEnrolls(page, filter) {
    return this.http.get(`/enrolls?page=${page}${filter}&order_column=section_id&order_direction=ASC`);
  }

  lastEnrollsAll(filter) {
    return this.http.get(`/enrolls?render=all${filter}&order_column=section_id&order_direction=ASC`);
  }

  lastEnrollsReport(filter, type) {
    return this.http.get(`/reports/enroll-excel?render=all${filter}&order_column=section_id&order_direction=ASC&opt=${type}`, { responseType: 'blob' });
  }
  enrollsByStatus(page,filter) {
    return this.http.get(`/reports/enrolls/payments?page=${page}${filter}&order_column=paid_at&order_direction=ASC`);
  }

  enrollsByStatusAll(filter) {
    return this.http.get(`/reports/enrolls/payments?render=all${filter}&order_column=paid_at&order_direction=ASC`);
  }

  enrollsByStatusReport(filter, type) {
    return this.http.get(`/reports/payments-excel?render=all${filter}&order_column=paid_at&order_direction=ASC&opt=${type}`, { responseType: 'blob' });
  }

}

export default new ReportService();
