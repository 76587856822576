import {camelCase, mapKeys, mapValues, snakeCase, has, isPlainObject, transform} from 'lodash';

class AppHelper {

  static pct(percentage, value) {
    let temp = (percentage * value) / 100;
    return Math.round(temp);
  }

  /**
   * Get Errors.
   * @param {Object} error - Error.
   * @returns {string}
   */
  static getError(error) {
    const defaultMessage = 'Unknown error';

    let message = has(error, 'response.data.message')
      ? error.response.data.message
      : defaultMessage;

    if (message === defaultMessage) {
      message = has(error, 'response.data.msg')
        ? error.response.data['msg']
        : defaultMessage;
    }

    if (message === defaultMessage) {
      message = has(error, 'message') ? error.message : defaultMessage;
    }

    return message;
  }

  /**
   * Get Laravel Errors.
   * @param {Object} error - Error.
   * @param {string} rule - Get error from Laravel.
   * @returns {string}
   */
  static getLaravelError(error, rule= '') {
    const defaultMessage = 'Unknown error';

    let message = (rule !== '' && has(error, 'response.data.errors.' + rule))
      ? error.response.data['errors'][rule][0]
      : defaultMessage;

    if (message === defaultMessage) {
      message = has(error, 'response.data.message')
        ? error.response.data.message
        : defaultMessage;
    }

    if (message === 'Wrong number of segments') {
      message = 'Invalid Token';
    }
    return message;
  }

  static mergeModels(modelA, modelB) {
    return mapValues(modelA, function(value, key) {
      if (has(modelB, key)) {
        return modelB[key];
      }
      return value;
    });
  }

  static toSnakeCase(model) {
    return mapKeys(model, function(value, key) {
      return snakeCase(key);
    });
  }

  /**
   * Convert to CamelCase.
   * @param {Object} data - Snake Case data format.
   * @returns {Object}
   */
  static toCamelCase(data) {
    return transform(data, (result, value, key) => {
      // Check if theres is a JSON child
      if (isPlainObject(value)) {
        const nKey = camelCase(key);
        // Recursive function
        result[nKey] = AppHelper.toCamelCase(value);
      } else {
        const nKey = camelCase(key);
        result[nKey] = value;
      }
    }, {});
  }

  static sendError(error) {
    throw error;
  }
  static getLabelPayment (val) {
    let option = {}
    switch(val) {
      case 1 : option = {
                class: 'tag tag-success',
                label: 'Pagado'
              };
              break;
      case 3 : option = {
                class: 'tag tag-info',
                label: 'Exonerado'
              };
              break;
      case 4 : option = {
                class: 'tag tag-warning',
                label: 'Compromiso de Pago'
              };
              break;
      case 5 : option = {
                class: 'tag tag-primary',
                label: 'Retirado'
              };
              break;   

      default: option = {
                class: 'tag tag-danger',
                label: 'Pendiente'
              };
              break;
    }
    return option;
  }

  static getLabelEnroll (val) {
    let option = {}
    switch(val) {
      case 1 : option = {
                class: 'tag tag-success',
                label: 'Activo'
              };
              break;
      case 3 : option = {
                class: 'tag tag-info',
                label: 'Exonerado'
              };
              break;
      case 4 : option = {
                class: 'tag tag-danger',
                label: 'Retirado'
              };
              break;
      case 5 : option = {
                class: 'tag tag-primary',
                label: 'Anulado'
              };
              break;
      case 6 : option = {
                class: 'tag tag-info',
                label: 'Reservado'
              };
              break;   

      default: option = {
                class: 'tag tag-warning',
                label: 'Pendiente'
              };
              break;
    }
    return option;
  }

  static getEnrollType (val) {
    let option = {}
    switch(val) {
      case 1 : option = {
                label: 'Virtual'
              };
              break;
      case 2 : option = {
                label: 'Semiprencial'
              };
              break;
      case 3 : option = {
                label: 'Presencial'
              };
              break; 
      default: option = {
                label: 'Virtual'
              };
              break;
    }
    return option;
  }

  // static test () {
  //   return 'test';
  // }

  static showErrors (myObj) {
    let msg = '';
      for (const [key, value] of Object.entries(myObj)) {
        msg = msg + value + ", "
      }

      return msg;
  }


  static statusLabelTask (val) {
    switch(val) {
      case 'completed' : return {
                class: 'tag tag-success',
                name: 'Completado'
              };
              break;
      case 'requested' : return {
                class: 'tag tag-info',
                name: 'Solicitado'
              };
              break;
      case 'in_progress' : return {
                class: 'tag tag-warning',
                name: 'En progreso'
              };
              break;
      case 'failed' : return {
                class: 'tag tag-danger',
                name: 'Error'
              };
              break;
    }
  }
  
}

export default AppHelper;
