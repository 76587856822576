import { combineReducers } from 'redux';
import settings from './settings';
import user from './user';
import campus from './campus';
import filters from './filters';

const reducer = combineReducers({
  settings,
  user,
  campus,
  filters
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return reducer(state, action);
};

export default rootReducer;
