import AppService from "./AppService";

class SettingService extends AppService {
  list() {
    return this.http.get(`/settings?with=campus`);
  }

  create(data) {
    return this.http.post("/settings", data);
  }

  update(data, id) {
    return this.http.patch(`/settings/${id}`, data);
  }
  delete(id) {
    return this.http.delete(`/settings/${id}`);
  }
}

export default new SettingService();
