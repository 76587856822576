import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { updateCampus } from '../../redux/actions/campus';
import { Link } from "react-router-dom";
import {has} from 'lodash';

class Header extends Component {

  constructor(props) {
		super(props);
		this.state = {
      dropdownOpen: false
		};
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }


  hideLeftSidebarProxy = (e) => {
    const {
      authMenuAction,
      pagesMenuAction,
      languageMenuAction,
      mailMenuAction,
      notificationMenuAction,
    } = this.props;
    if (this.leftSidebar.contains(e.target)) {
      authMenuAction(false);
      pagesMenuAction(false);
      languageMenuAction(false);
      mailMenuAction(false);
      notificationMenuAction(false);
      return;
    }
  };

  assignCampus = (campus) => {
    const payload = {
      ...campus
    }
    this.props.dispatch(updateCampus(payload));
  }

  renderCampuses = () => {
    const campuses = has(this.props.user, 'campuses') ? this.props.user.campuses : [];
    const items = campuses.map((campus, index) => {
      return (<DropdownItem key={index} className="dropdown-item" onClick={() => this.assignCampus(campus)}>{campus.name}</DropdownItem>)
    });

    return items;
  }

  render() {
    const {
      fixNavbar,
      darkHeader,
      profileMenu,
      user,
    } = this.props;

    if (!user) {
      return window.location.href = '/login';
    }

    return (
      // < !--Start Page header-- >
      <div
        ref={(leftSidebar) => {
          this.leftSidebar = leftSidebar;
        }}
        className={`section-body ${fixNavbar ? "sticky-top" : ""} ${
          darkHeader ? "top_dark" : ""
        }`}
        id="page_top"
      >
        <div className="container-fluid">
          <div className="page-header">
            <div className="left">
            </div>
            <div className="right">
              <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle className="btn-primary" caret>
                <i className="fa fa-university pr-10 pl-10" />{this.props.campus.name} <i className="pl-10"></i>
                </DropdownToggle>
                <DropdownMenu>
                  {this.renderCampuses()}
                </DropdownMenu>
              </Dropdown>
              <div className="notification d-flex">

                <div className={`dropdown d-flex${profileMenu ? " show" : ""}`}>
                  <span
                    className="chip ml-3"
                    // onClick={() => profileMenuAction(!profileMenu)}
                  >
                    <span className="avatar" style={{backgroundImage: `url(../assets/images/xs/avatar${this.props.user.gender === 'F' ? 1 : 2}.jpg)`}} />
                    {this.props.user.firstName}
                  </span>
                  <div
                    className={`dropdown-menu dropdown-menu-right dropdown-menu-arrow${
                      profileMenu ? " show dProfile" : ""
                    }`}
                  >
                    <Link className="dropdown-item" to="/pageProfile">
                      <i className="dropdown-icon fe fe-user"></i> Profile
                    </Link>
                    <Link className="dropdown-item" to="/setting">
                      <i className="dropdown-icon fe fe-settings"></i> Settings
                    </Link>
                    <Link className="dropdown-item" to="/email">
                      <span className="float-right">
                        <span className="badge badge-primary">6</span>
                      </span>
                      <i className="dropdown-icon fe fe-mail"></i> Inbox
                    </Link>
                    <span className="dropdown-item">
                      <i className="dropdown-icon fe fe-send"></i> Message
                    </span>
                    <div className="dropdown-divider"></div>
                    <span className="dropdown-item">
                      <i className="dropdown-icon fe fe-help-circle"></i> Need
                      help?
                    </span>
                    <Link className="dropdown-item" to="/login">
                      <i className="dropdown-icon fe fe-log-out"></i> Sign out
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  boxShadow: state.settings.isBoxShadow,
  profileMenu: state.settings.isProfileMenu,
  pagesMenu: state.settings.isPagesMenu,
  mailMenu: state.settings.isMailMenu,
  languageMenu: state.settings.isLanguageMenu,
  authMenu: state.settings.isAuthMenu,
  fixNavbar: state.settings.isFixNavbar,
  darkHeader: state.settings.isDarkHeader,
  notificationMenu: state.settings.isNotificationMenu,
  user: state.user,
  campus: state.campus
});


export default connect(mapStateToProps)(Header);
