import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import Payment from '../models/Payment';
import { Formik, Form } from "formik";
import StudentSearchModal from '../components/common/StudentSearchModal';
import InputField from '../core/widgets/input.widget';
import SelectField from '../core/widgets/select.widget';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePickerField from '../core/widgets/datepicker.widget'
import 'dayjs/locale/es-mx';
import dayjs from 'dayjs/';
import * as yup from "yup";

const PaymentSchema = yup.object().shape({
  productId: yup.string().required("Elija el tipo de pago"),
  amount: yup.number().required('Ingrese el monto'),
  description: yup.string(),
  paidAt: yup.string().required('Ingrese la fecha de pago'),
  methodId: yup.number().required('Ingrese la foma de pago'),
});

const PaymentForm = ({ paymentData, products, getPaymentData, paymentMethods }) => {
  console.log(paymentData);
  const [payment, setPayment] = useState(paymentData);
  const [studentName, setStudentName] = useState(paymentData.userFullName)
  const [student, setStudent] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const isSending = useRef(false);

  useEffect(() => {
    setPayment(paymentData);
    setStudentName(paymentData.userFullName);
  }, [paymentData])

  useEffect(() => {
    if (isSending.current === true) {
      isSending.current = false;
      getPaymentData(payment);
    }
  }, [payment])

  useEffect(() => {
    if(student) {
      setPayment({
        ...payment,
        userId: student.id,
        userFullName:  student.fullName
      })
    }
    
  }, [openModal])

  const selectEstudent = item => {
    setOpenModal(false);
    setStudentName(item.fullName);
    setStudent(item);
  }

  const changeOpenModal = val => {
    setOpenModal(val)
  }

  return (
    <>
      <StudentSearchModal
        selectEstudent={selectEstudent}
        isOpen={openModal}
        changeOpenModal={changeOpenModal}
      />
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Nuevo Pago</h3>
            </div>
            <div className="card-body">
             <Formik
                initialValues={{...paymentData}}
                validationSchema={PaymentSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  values.userFullName =  studentName;
                  values.userId = student ? student.id : '';
                  values.paidAt = dayjs(values.paidAt).format('YYYY-MM-DD');
                  values.total = values.amount;
                  values.status = 1;
                  console.log(values);
                  getPaymentData(values);
                }}
             >
               {(values) => (
                <Form>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Fecha de Pago<span className="text-danger">*</span></label>
                    <div className="col-md-7">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale='es-mx'
                      >
                        <DatePickerField
                          name='paidAt'
                          value={values.paidAt ? dayjs(values.paidAt).format("YYYY-MM-DD") : ''}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Alumno <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <TextField name="studentName" value={studentName} fullWidth disabled variant='outlined' size='small' />
                    </div>
                    <div className="col-md-2">
                      <button type="button" className="mr-1 btn btn-primary" onClick={() => setOpenModal(true)} >
                        Buscar
                      </button>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Producto <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <SelectField
                        name="productId"
                        data={products.map(item => {
                          return {key: item.name, value: item.id}
                        })}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Forma de Pago <span className="text-danger">*</span></label>
                    <div className="col-md-7">
                      <SelectField
                        name="methodId"
                        data={paymentMethods.map(item => {
                          return {key: item.name, value: item.id}
                        })}
                      />
                    </div>
                  </div>
                  { values.methodId === 2 && <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Nº Operación <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <InputField name="noOperation" />
                    </div>
                  </div> }
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Monto <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <InputField name="amount" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Descripción
                    </label>
                    <div className="col-md-7">
                      <InputField name="description" />
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="mr-1 btn btn-primary">
                      Guardar
                    </button>
                  </div>
                </Form>
               )}
             </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
