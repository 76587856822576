import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from "classnames";
import CampusService from '../services/CampusService';
import SettingService from '../services/SettingService';
import SettingTable from '../tables/SettingTable';
import SettingForm from '../forms/SettingForm';
import Setting from '../models/Setting';
import { connect } from "react-redux";
import { useUI, usePageUI } from '../app/context/ui';
import Swal from "sweetalert2";

const SettingPage = (props) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [campuses, setCampuses] = useState([]);
  const [settings, setSettings] = useState([]);
  const [settingData, setSettingData] = useState(Setting);
  const dataInicial = Setting;

  useEffect(() => {
    SettingService.init(props.user.accessToken);
    CampusService.init(props.user.accessToken);
    (async function getRecords() {
      await getSettings();
      await getCampuses();
    })();
  }, [props.user]);

  const getSettings = async () => {
    setLoading(true);
    try {
      const response = await SettingService.list();
      setSettings(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCampuses = async () => {
    try {
      const response = await CampusService.list();
      setCampuses(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSettingData = async (data) => {
    blockUI.current.open(true, 'Estamos guardando...');
    let response = "";
    try {
      if (data.id) {
        response = await SettingService.update(data, data.id);
      } else {
        response = await SettingService.create(data);
      }
      setActiveTab(1);
      resetSetting();
      setIsValid(true);
      getSettings();
      blockUI.current.open(false);
      alertUI.current.open(true, response.data.message, 'success');
    } catch (error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
    }
  };

  const getData = (data, action) => {
    
    setSettingData(data);
    if (action === "edit") {
      setActiveTab(2);
    } else {
      deleteSetting(data);
    }
  };

  const resetSetting = () => {
    setSettingData(dataInicial);
  };

  const deleteSetting = (data) => {
    Swal.fire({
      title: "Estas Seguro?",
      text: "No podrás recuperar este registro eliminado!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#333",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        blockUI.current.open(true, 'Estamos eliminando...');
        try {
          const response = await SettingService.delete(data.id);
          if (response) {
            getSettings();
            blockUI.current.open(false);
            alertUI.current.open(true, response.data.message, 'success');
          }
        } catch (error) {
          console.log(error);
          blockUI.current.open(false);
          alertUI.current.open(true, 'Error', 'danger');
        }
      }
    });
  };

  const openTabAdd = () => {
    resetSetting();
    setIsValid(false);
    setActiveTab(2);
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Ajustes</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {props.user.school ? props.user.school : 'YAKHANA'}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Ajustes
                </li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setActiveTab(1)}
                >
                  Listado
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => openTabAdd()}
                >
                  Agregar
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className={classnames(["fade show"])}>
              <SettingTable
                isLoading={loading}
                data={settings}
                getData={getData}
              />
            </TabPane>
            <TabPane tabId={2} className={classnames(["fade show"])}>
              <SettingForm
                getSettingData={getSettingData}
                settingData={settingData}
                isValid={isValid}
                campuses={campuses}
              />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(SettingPage);
