import AppService from "./AppService";

class SharedService extends AppService {
  list(page, limit) {
    return this.http.get(`/sections?page=${page}&limit=${limit}`);
  }

  create(data) {
    return this.http.post("/sections", data);
  }

  update(data, id) {
    return this.http.patch(`/sections/${id}`, data);
  }
  delete(id) {
    return this.http.delete(`/sections/${id}`);
  }
}

export default new SharedService();
