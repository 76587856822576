import React, { useState } from "react";
import Spinner from '../components/Shared/Spinner';
import Pagination from 'react-js-pagination';
import Moment from 'moment';

const PaymentTable = (props) => {
  const data = props.data;
  const [limit, setLimit] = useState(25);

  const editPayment = (item) => {
    props.getData(item, "edit");
  };

  const deletePayment = (item) => {
    props.getData(item, "delete");
  };

  const handlerChange = e => {
    setLimit(e.target.value)
    props.getLimit(e.target.value)
  }

  const handlePageChange = page => {
    props.getPage(page)
  }

  return (
    <>
    <div className="text-right mb-2">
      <div className="offset-10 col-sm-2">
        <select className="form-control input-height" onChange={handlerChange} value={limit}>
          <option value="2">2</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    </div>
      <div className="table-responsive card">
        <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
          <thead>
            <tr>
              <th>Fecha de pago</th>
              <th>Concepto de pago</th>
              <th>Monto</th>
              <th>Forma de Pago</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{Moment(item.paidAt).format('DD/MM/YYYY')}</td>
                  <td>{item.productName}</td>
                  <td>S/ {item.total}</td>
                  <td>{item.methodId == 1 ? 'Efectivo' : 'Deposito/Transferencia'}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-icon btn-sm"
                      title="Edit"
                      onClick={() => editPayment(item)}
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      onClick={() => deletePayment(item)}
                      type="button"
                      className="btn btn-icon btn-sm js-sweetalert"
                      title="Delete"
                      data-type="confirm"
                    >
                      <i className="fa fa-trash-o text-danger"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        { props.isLoading && <Spinner />}
        { props.pagination.lastPage > 1 && <div className="d-flex justify-content-center">
          <Pagination
            activePage={props.pagination.activePage}
            itemsCountPerPage={props.pagination.itemsCountPerPage}
            totalItemsCount={props.pagination.totalItemsCount}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass='page-item '
            linkClass='page-link'
          />        
        </div> }
      </div>
    </>
  );
};

export default PaymentTable;
