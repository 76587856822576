import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import AppHelper from '../../helpers/AppHelper';
import Moment from 'moment';
let option = {}
const opt = (s) => {
  switch(s) {
    case 1 :  option = {
              label: 'Pagado'
            };
            break;
    case 3 :  option = {
              label: 'Exonerado'
            };
            break;
    case 4 :  option = {
              label: 'Compromiso de Pago'
            };
            break;
    default:  option = {
              label: 'Pendiente'
            };
            break;
  }
  return option;
}

const PaymentsReport = (props) => {
  return(
    <>
      <table id="emp" className="table" style={{display: "none"}}>
        <thead>
          <tr>
            <th>DNI</th>
            <th>Apellidos y Nombres</th>
            <th>Año</th>
            <th>Nivel</th>
            <th>Grado</th>
            <th>Sección</th>
            <th>Monto</th>
            <th>Pensión</th>
            <th>Fecha</th>
            <th>Modalidad</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
            {props.data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.userDni}</td>
                    <td>{item.userFullName}</td>
                    <td>{item.schoolYearName}</td>
                    <td>{item.levelName}</td>
                    <td>{item.gradeName}</td>
                    <td>{item.sectionShortCode}</td>
                    <td>S/ {item.paymentStatus === 1 ? item.total : item.amount}</td>
                    <td>{item.paymentMonth}</td>
                    <td>{item.paidAt ? Moment(item.paidAt).format('DD/MM/YYYY') : ''}</td>
                    <td>{AppHelper.getEnrollType(item.enrollType).label}</td>
                    <td>{opt(item.paymentStatus).label}</td>
                  </tr>
                );
              })
            }
          </tbody>
      </table>
      <div>
          <ReactHTMLTableToExcel
            className="btn btn-info"
            table="emp"
            filename="ReportExcel"
            sheet="Sheet"
            buttonText="Exportar a excel"
          />
      </div>
    </>
  )
}

export default PaymentsReport;
