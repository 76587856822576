import React, {useState, useEffect} from 'react';
import { Button, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePickerField from '../core/widgets/datepicker.widget'
import 'dayjs/locale/es-mx';
import dayjs from 'dayjs/';
import * as yup from "yup";
import InputField from '../core/widgets/input.widget';
import SelectField from '../core/widgets/select.widget';
import StudentAgentSearchModal from '../components/university/Students/StudentAgentSearchModal';

const genderList = [
  {value: '', key: 'Seleccione un género'},
  {value: 'F', key: 'Femenino'},
  {value: 'M', key: 'Masculino'}
];

const relationshipList = [
  {value: '', key: 'Seleccione'},
  {value: 'madre', key: 'Madre'},
  {value: 'padre', key: 'Padre'},
  {value: 'tia', key: 'Tia'},
  {value: 'tio', key: 'Tio'},
  {value: 'hermana', key: 'Hermana'},
  {value: 'hermano', key: 'Hermano'}
];

const digitsOnly = (value) => /^\d+$/.test(value);

const StudentSchema = yup.object().shape({
  dni: yup.string()
  .required('Campo requerido y solo números.')
  .typeError('El DNI solo debe contener números.')
  .test('Digits only', 'El DNI solo debe contener números', digitsOnly)
  .test('len', 'El DNI debe tener 8 digitos.', val => val && val.toString().length === 8 ),
  firstName: yup.string().required('Campo obligatorio'),
  lastName: yup.string().required('Campo obligatorio'),
  surname: yup.string().required('Campo obligatorio'),
  gender: yup.string().required('Campo obligatorio'),
  phone: yup.string(),
  email: yup.string().email('El email no es valido'),
  agentRelationship: yup.string(),
  agentFullName: yup.string()
});

const StudentForm = ({studentData, agents, searchAgent, saveStudent}) => {
  
  const [openModal, setOpenModal] = useState(false);
  const [agent, setAgent] = useState('');

  const changeOpenModal = val => {
    setOpenModal(val);
  }
  const changeSearch = val => {
    searchAgent(val);
  }

  useEffect(() => {
    if (agents) {
      setAgent(agents.fullName);
    }
  }, [agents])

  useEffect(() => {
    setAgent(studentData.agentFullName);
  }, [studentData])


  return (
    <>
      <StudentAgentSearchModal
        isOpen={openModal}
        changeOpenModal={changeOpenModal}
        changeSearch={changeSearch}
      />
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Información Básica del alumno</h3>
            </div>
            <Formik
              initialValues={
                {
                  ...studentData
                }
              }
              validationSchema={StudentSchema}
              enableReinitialize={true}
              onSubmit={
                (values, { resetForm }) => {
                  values.agentFullName =  agent ?? '';
                  values.agentId = agents ? agents.id : '';
                  values.birthdate = values.birthdate ? dayjs(values.birthdate).format('YYYY-MM-DD') : '';
                  saveStudent(values);
                  // resetForm();
                  setAgent('');
                }
              }
            >
              {() => (
                <Form className="card-body">
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">DNI <span className="text-danger">*</span></label>
                    <div className="col-md-7">
                      <InputField
                      name="dni"
                      inputProps={{
                        maxLength: 8
                      }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Nombres <span className="text-danger">*</span></label>
                    <div className="col-md-7">
                      <InputField name="firstName" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Apellido Paterno <span className="text-danger">*</span></label>
                    <div className="col-md-7">
                    <InputField name="lastName" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Apellido Materno <span className="text-danger">*</span></label>
                    <div className="col-md-7">
                    <InputField name="surname" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label" >Fecha Nacimiento</label>
                    <div className="col-md-7">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale='es-mx'
                      >
                        <DatePickerField
                          name='birthdate'
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Género <span className="text-danger">*</span></label>
                    <div className="col-md-7">
                      <SelectField
                        name="gender"
                        data={genderList}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Dirección</label>
                    <div className="col-md-7">
                    <InputField name="address"/>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Apoderado
                    </label>
                    <div className="col-md-7">
                      <TextField name="agent" value={agent} fullWidth disabled variant='outlined' size='small' />
                    </div>
                    <div className="col-md-2">
                      <Button type="button" variant='contained' className="mr-1 btn btn-primary" onClick={() => setOpenModal(true)} >
                        Buscar
                      </Button>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Relación con el apoderado</label>
                    <div className="col-md-7">
                      <SelectField
                        name="agentRelationship"
                        data={relationshipList}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Colegio de Procedencia</label>
                    <div className="col-md-7">
                      <InputField name="schoolFrom" />
                    </div>
                  </div>
                  <div className="text-center">
                    <Button type="submit" variant='contained' className="mr-1 btn btn-primary">
                      Guardar
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            
          </div>
        </div>
      </div>
    </>
  )

}

export default StudentForm;
