import AppService from './AppService';

class SharedService extends AppService {

  listlevels() {
    return this.http.get('/levels');
  }

  listGrades() {
    return this.http.get(`/grades`);
  }

  listGradesByLevel(levelId) {
    return this.http.get(`/grades?level_id=${levelId}`);
  }

  listSections(page, limit) {
    return this.http.get(`/sections?page=${page}&limit=${limit}`);
  }

  listSectionsByGrade(gradeId, yearId) {
    return this.http.get(`/sections?grade_id=${gradeId}&school_year_id=${yearId}`);
  }

}

export default new SharedService();
