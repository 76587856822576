import AppService from './AppService';

class SchoolYearService extends AppService {
  list() {
    return this.http.get('/years');
  }

  create(data) {
    return this.http.post('/years', data);
  }

  update(data, id) {
    return this.http.patch(`/years/${id}`, data);
  }
}

export default new SchoolYearService();