import React, {useState, useEffect, useReducer} from 'react';
import { connect } from 'react-redux';
import { useUI, usePageUI } from '../app/context/ui';
import FilterByStatus from '../components/Filters/FiltersByStatus';
import ReportService from '../services/ReportService';
import Spinner from '../components/Shared/Spinner';
import Pagination from 'react-js-pagination';
import ExportExcel from '../components/Reports/PaymentsReport';
import ExportDni from '../components/Reports/ListDniReport';
import Moment from 'moment';
import AppHelper from '../helpers/AppHelper'

const ReportPaymentByStatusTable = (props) => {
  Moment.locale('es');
  const event = new Date();
  const options = { month: 'long'};
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState(`&year=15&status=1&month=${event.toLocaleDateString('es-PE', options)}&start_date=${Moment().format('yyyy-MM-DD')}&end_date=${Moment().format('yyyy-MM-DD')}`);
  const [data, setData] = useState([]);
  const [report, setReport] = useState([]);
  const [pagination, setPagination] = useState(
    {
      activePage: 1,
      itemsCountPerpage: 1,
      totalItemsCount: 1,
      lastPage: 1
    },
  )

  useEffect(() => {
    ReportService.init(props.user.accessToken, props.campus.id);
    getReport(page, query);
  }, [props.campus]);

  useEffect(() => {
    getReport(page, query);
  }, [page]);

  const getReport = async(page, fil) => {
    setLoading(true);
    try{
      const response = await ReportService.enrollsByStatus(page, fil);
      setData(response.data.data);
      setTotal(response.data.total);
      setPagination({
        activePage : response.data.currentPage,
        itemsCountPerpage : response.data.perPage,
        totalItemsCount : response.data.total,
        lastPage : response.data.lastPage
      });
      setLoading(false);
    } catch(error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
      setLoading(false);
    }
  }

  const getReportAll = async(fil) => {
    blockUI.current.open(true, 'Estamos buscando...');
    try{
      const response = await ReportService.enrollsByStatusReport(query, 0);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      file.download = `reporte-de-pagos-${Moment().format('YYYY-MM-DD-H-m-s')}.xlsx`;
      file.click();
      blockUI.current.open(false);
    } catch(error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
    }
  }

  const getReportOut = async(fil) => {
    blockUI.current.open(true, 'Estamos buscando...');
    try{
      const response = await ReportService.enrollsByStatusReport(query, 1);
      console.log(response);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      console.log(url);
      let file = document.createElement('a');
      file.href = url;
      file.download = `reporte-de-deudores-${Moment().format('YYYY-MM-DD-H-m-s')}.xlsx`;
      file.click();
      blockUI.current.open(false);
    } catch(error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
    }
  }

  const handlePageChange = page => {
    setPage(page);
  }

  const setFilter = (fil) => {
    setQuery(fil);
    getReport(page, fil)
    // getReportAll(fil);
  }

  return (
    <>
      <FilterByStatus setFilter={setFilter} />
      <div className="table-responsive card">
        <div style={{display: 'flex', justifyContent: 'flex-end'}} >
          <div style={{marginRight: 10}}>
            {/* <ExportDni data={report ? report : []} /> */}
            <button className="center btn btn-primary" onClick={getReportOut}>
              Exportar deudores
            </button>
          </div>
          <div>
            {/* <ExportExcel data={report ? report : []} /> */}
            <button className="center btn btn-primary" onClick={getReportAll}>
              Exportar a excel
            </button>
          </div>
        </div>
      </div>
      <div className="table-responsive card">
        <div className="text-right">
          <h2 className="page-title">Total: {total}</h2>
        </div>
      </div>
      <div className="table-responsive card">
        <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
          <thead>
            <tr>
              <th>DNI</th>
              <th>Apellidos y Nombres</th>
              <th>Año</th>
              <th>Nivel</th>
              <th>Grado</th>
              <th>Sección</th>
              <th>Pago</th>
              <th>Fecha</th>
              <th>Monto</th>
              <th>Modalidad</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.userDni}</td>
                    <td>{item.userFullName}</td>
                    <td>{item.schoolYearName}</td>
                    <td>{item.levelName}</td>
                    <td>{item.gradeName}</td>
                    <td>{item.sectionShortCode}</td>
                    <td>{item.paymentMonth ? item.paymentMonth : 'Matrícula'}</td>
                    <td>{item.paidAt ? Moment(item.paidAt).format('DD/MM/YYYY') : ''}</td>
                    <td>S/ {item.paymentStatus === 1 ? item.total : item.amount}</td>
                    <td>{AppHelper.getEnrollType(item.enrollType).label}</td>
                    <td>
                      <span className={AppHelper.getLabelPayment(item.paymentStatus).class}>
                        {AppHelper.getLabelPayment(item.paymentStatus).label}
                      </span>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        { loading && <Spinner />}
        { pagination.lastPage > 1 && <div className="d-flex justify-content-center">
          <Pagination
            activePage={pagination.activePage}
            itemsCountPerPage={pagination.itemsCountPerPage}
            totalItemsCount={pagination.totalItemsCount}
            pageRangeDisplayed={pagination.lastPage >= 5 ? 5 : pagination.lastPage}
            onChange={handlePageChange}
            itemClass='page-item '
            linkClass='page-link'
          />
        </div> }
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
    return {
      user: state.user,
      campus: state.campus
    };
  };

  export default connect(mapStateToProps)(ReportPaymentByStatusTable);
