import React, {useState, useEffect} from 'react'
import StudentService from '../../services/StudentService';
import AgentTable from '../../tables/AgentTable';
import { connect } from 'react-redux';
import AgentService from '../../services/AgentService';

const ListPage = (props) => {
  const [agent, setAgent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const [pagination, setPagination] = useState({
    activePage: 1,
    itemsCountPerpage: 1,
    totalItemsCount: 1,
    lastPage: 1
  });

  useEffect(() => {
    StudentService.init(props.user.accessToken, props.campus.id);
    AgentService.init(props.user.accessToken, props.campus.id);
  }, [props.campus, props.user]);

  useEffect(() => {
    (async function getRecords() {
      await getAgents(page, limit, filter);
    })();
  }, [limit, page, filter, props.campus, props.isLoad]);

  const getAgents = async (p, lim, fil) => {
    setLoading(true);
    try {
      const response = await AgentService.list(p,lim,fil)
      setAgent(response.data.data)
      setPagination({
        activePage: response.data.currentPage,
        itemsCountPerpage: response.data.perPage,
        totalItemsCount: response.data.total,
        lastPage: response.data['lastPage']
      })
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  const changeLimit = lim => {
    setPage(1)
    setLimit(lim)
  }
  const changePage = p => {
    setPage(p)
  }

  const changeSearch = value => {
    setFilter(value)
  }

  const getAgent = (val, action) => {
    props.getAgent(val, action)
  }

  return (
    <>
      <AgentTable
        data={agent}
        changeLimit={changeLimit}
        pagination={pagination}
        changePage={changePage}
        changeSearch={changeSearch}
        getAgent={getAgent}
        isLoading={loading}
      />
    </>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    campus: state.campus
  };
};


export default connect(mapStateToProps)(ListPage);