import AppService from "./AppService";

class ExpenseService extends AppService {

  list(page, limit) {
    return this.http.get(`/expenses?page=${page}&limit=${limit}&with=campus`);
  }

  create(data) {
    return this.http.post("/expenses", data);
  }

  update(data, id) {
    return this.http.patch(`/expenses/${id}`, data);
  }
  delete(id) {
    return this.http.delete(`/expenses/${id}`);
  }
}

export default new ExpenseService();
