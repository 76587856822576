import AppService from "./AppService";

class PaymentService extends AppService {

  list(page, limit) {
    return this.http.get(`/payments?page=${page}&limit=${limit}&type=others&with=product,user`);
  }

  listMethods() {
    return this.http.get(`/payments/methods?limit=0`);
  }

  listMethodsBank(parentId) {
    return this.http.get(`/payments/methods?parent_id=${parentId}&limit=0`);
  }

  downloadDocument(id) {
    return this.http.get(`/payments/invoice/${id}`, { responseType: 'blob' });
  }

  create(data) {
    return this.http.post("/payments", data);
  }

  update(data, id) {
    return this.http.patch(`/payments/${id}`, data);
  }
  delete(id) {
    return this.http.delete(`/payments/${id}`);
  }
}

export default new PaymentService();
