import AppService from "./AppService";

class EnrollService extends AppService {
  list(page, limit, filter = null) {
    if (filter === null) {
      return this.http.get(
        `/enrolls?page=${page}&limit=${limit}&order_column=id&order_direction=DESC`
      );
    } else {
      return this.http.get(
        `/enrolls?page=${page}&limit=${limit}${filter}&order_column=id&order_direction=DESC`
      );
    }
  }

  listEnrollsAll(filter) {
    return this.http.get(
      `/enrolls?render=all${filter}&order_column=section_id&order_direction=ASC`
    );
  }

  listPayments(enrollId) {
    return this.http.get(`/payments?enrollId=${enrollId}&limit=0`);
  }

  create(data) {
    return this.http.post("/enrolls", data);
  }

  update(data, id) {
    return this.http.patch(`/enrolls/${id}`, data);
  }
  delete(id) {
    return this.http.delete(`/enrolls/${id}`);
  }

  listEnrollsDocuments(yearId, sectionId, campusId, status = 1) {
    return this.http.get(
      `/enrolls?render=all&school_year_id=${yearId}&section_id=${sectionId}&campus_id=${campusId}&status=${status}&&order_column=full_name&order_direction=ASC`
    );
  }
}

const enrollService = new EnrollService();
Object.freeze(enrollService);

export default enrollService;
