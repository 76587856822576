import React, {useState, useRef, useEffect} from 'react';
import {useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import Spinner from '../components/Shared/Spinner';
import AppHelper from '../helpers/AppHelper';
import ExportExcel from '../components/Reports/ReportEnroll';
import ReportService from '../services/ReportService';
import Moment from 'moment';


const EnrollTable = (props) => {
  const history = useHistory();
  const [limit, setLimit] = useState(25);
  const [code, setCode] = useState('');
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [year, setYear] = useState('');
  const [level, setLevel] = useState('');
  const [grade, setGrade] = useState('');
  const [section, setSection] = useState('');
  const [status, setStatus] = useState('');
  const [enrollType, setEnrollType] = useState('');
  const [showBtn, setShowBtn] = useState(false);
  const {data, report} = props;
  const isFilter = useRef(false);

  useEffect(() => {
    if (isFilter.current === true) {
      isFilter.current = false;
      props.changeSearch(filter);
    }
  }, [filter])

  const handlerChange = e => {
    setLimit(e.target.value)
    props.changeLimit(e.target.value)
  }

  const handlePageChange = page => {
    props.changePage(page)
  }

  const handlerChangeSearch = e => {
    setSearch(e.target.value);
    if(e.target.value.length > 2 || e.target.value === '') {
      isFilter.current = true;
      setFilter(`&user_filter=${e.target.value}`)
    }
  }
  const handlerChangeCode = e => {
    setCode(e.target.value);
    if(e.target.value.length > 2 || e.target.value === '') {
      isFilter.current = true;
      setFilter(`&user_filter=${e.target.value}`);
    }
  }

  const handlerChangeYear = e => {
    setYear(e.target.value);
  }
  const handlerChangeLevel = e => {
    setLevel(e.target.value);
    props.changeGrades(e.target.value);
  }
  const handlerChangeGrade = e => {
    setGrade(e.target.value);
    props.changeSections(e.target.value, year);
  }
  const handlerChangeSection = e => {
    setSection(e.target.value);
  }

  const handlerChangeStatus= e => {
    setStatus(e.target.value);
  }
  const handlerChangeType= e => {
    setEnrollType(e.target.value);
  }

  const seePayments = (item) => {
    props.getEnroll(item, 'payments');
  }

  const editEnroll = (item) => {
    props.getEnroll(item, 'edit');
  }

  const handlerFilter = () => {
    setShowBtn(true);
    isFilter.current = true;
    const y = year ? '&school_year_id=' + year : '';
    const l = level ? '&level_id=' + level : '';
    const g = grade ? '&grade_id=' + grade : '';
    const s = section ? '&section_id=' + section : '';
    const et = enrollType ? '&enroll_type=' + enrollType : '';
    const st = status ? '&status=' + status : '';

    setFilter(y + l + g + s + et + st);
  }

  const handlerReset = () => {
    setShowBtn(false);
    isFilter.current = true;
    setYear('');
    setLevel('');
    setGrade('');
    setSection('');
    setStatus('');
    setEnrollType('');
    setFilter('');
  }

  const getReportEnroll = async() => {
    try {
      ReportService.init(props.user.accessToken, props.campus.id);
      const response = await ReportService.lastEnrollsReport(filter, 1);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      file.download = `reporte-de-matricula-${Moment().format('YYYY-MM-DD-H-m-s')}.xlsx`;
      file.click();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="row mb-2">
        <div className="col-sm-6 text-left">
          <h5>
            Total: {props.pagination.totalItemsCount}
          </h5>
        </div>
        <div className="offset-2 col-sm-2">
        {
          showBtn &&
          <button className="center btn btn-primary" onClick={getReportEnroll}>
            Exportar a excel
          </button>
        }
        </div>
        <div className="col-sm-2">
          <select className="form-control input-height" onChange={handlerChange} value={limit}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <div className="table-responsive card">
        <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
          <thead>
            <tr>
              <th>DNI</th>
              <th>Apellidos y Nombres</th>
              <th>Periodo</th>
              <th>Nivel</th>
              <th>Grado</th>
              <th>Sección</th>
              <th>Pensión</th>
              <th>Modalidad</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input type="search" className="form-control" onChange={handlerChangeCode} value={code} />
              </td>
              <td>
                <input type="search" className="form-control" onChange={handlerChangeSearch} value={search} />
              </td>
              <td>
                <select className="form-control" name="year" value={year} onChange={handlerChangeYear}>
                  <option value="">Seleccionar</option>
                  {
                    props.schoolYear.map(item => {
                      return(
                        <option value={item.id} key={item.id}>{item.name}</option>
                      )
                    })
                  }
                </select>
              </td>
              <td>
                <select className="form-control" name="level" value={level} onChange={handlerChangeLevel}>
                  <option value="">Seleccionar</option>
                  {
                    props.levels.map(item => {
                      return(
                        <option value={item.id} key={item.id}>{item.name}</option>
                      )
                    })
                  }
                </select>
              </td>
              <td>
                <select className="form-control" name="grade" value={grade} onChange={handlerChangeGrade}>
                  <option value="">Seleccionar</option>
                  {
                    props.grades.map(item => {
                      return(
                        <option value={item.id} key={item.id}>{item.name}</option>
                      )
                    })
                  }
                </select>
              </td>
              <td>
                <select className="form-control" name="section" value={section} onChange={handlerChangeSection}>
                  <option value="">Seleccionar</option>
                  {
                    props.sections.map(item => {
                      return(
                        <option value={item.id} key={item.id}>{item.shortCode}</option>
                      )
                    })
                  }
                </select>
              </td>
              <td></td>
              <td>
              <select
                  className="form-control"
                  name="type"
                  value={enrollType}
                  onChange={handlerChangeType}
                >
                  <option value="">Todo</option>
                  <option value="1">Virtual</option>
                  <option value="2">Semiprencial</option>
                  <option value="3">Presencial</option>
                </select>
              </td>
              <td>
                <select
                  className="form-control"
                  name="status"
                  value={status}
                  onChange={handlerChangeStatus}
                >
                  <option value="">Todo</option>
                  <option value="1">Activo</option>
                  <option value="2">Pendiente</option>
                  <option value="3">Exonerado</option>
                  <option value="4">Retirado</option>
                  <option value="5">Anulado</option>
                  <option value="6">Reservado</option>
                </select>
              </td>
              {/* <td></td> */}
              <td>
                <button type="submit" className="mr-1 btn btn-success" onClick={() => handlerFilter()}>
                  <i className="fa fa-search" alt="Buscar"></i>
                </button>
                <button type="submit" className="mr-1 btn btn-warning" onClick={() => handlerReset()}>
                <i className="fa fa-trash"></i>
                </button>
              </td>
            </tr>
            {
              data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{ item.userDni }</td>
                    <td>{ item.userFullName.substring(0, 30) }</td>
                    <td>{ item.schoolYearName }</td>
                    <td>{ item.levelName }</td>
                    <td>{ item.gradeName }</td>
                    <td>{ item.sectionShortCode }</td>
                    <td>S/ { item.pensionAmount }</td>
                    <td>{AppHelper.getEnrollType(item.enrollType).label}</td>
                    <td>
                      <span className={AppHelper.getLabelEnroll(item.status).class}>
                        {AppHelper.getLabelEnroll(item.status).label}
                      </span> 
                    </td>
                    {/* <td>S/ { item.enrollAmount }</td> */}
                    
                    <td>
                      <button type="button" className="btn btn-icon btn-sm" title="Ver pagos" onClick={() => seePayments(item)}><i className="fa fa-list"></i></button>
                      {/* <button type="button" className="btn btn-icon btn-sm" title="View"><i className="fa fa-eye"></i></button> */}
                      <button type="button" className="btn btn-icon btn-sm" title="Editar Matrícula" onClick={() => history.push(`/enrolls/${item.id}/edit`, {enroll: item})}><i className="fa fa-edit"></i></button>
                      {/* <button onClick={() => setShow(true)} type="button" className="btn btn-icon btn-sm js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger"></i></button> */}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        {
          props.isLoading && <Spinner />
        }
        {
          props.pagination.lastPage > 1 &&
          <div className="d-flex justify-content-center">
            <Pagination
              activePage={props.pagination.activePage}
              itemsCountPerPage={parseInt(props.pagination.itemsCountPerPage)}
              totalItemsCount={props.pagination.totalItemsCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass='page-item '
              linkClass='page-link'
            />
          </div>
        }
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(EnrollTable);
