import AppService from './AppService';

class UserService extends AppService {
  me() {
    return this.http.get('/me');
  }

  listRoles() {
    return this.http.get(`/auth/roles`);
  }

  list(page, limit, filter = null) {
    if (filter === null) {
      return this.http.get(`/users?type=system&page=${page}&limit=${limit}`);
    } else {
      return this.http.get(`/users?type=system&page=${page}&limit=${limit}&filter=${filter}`);
    }
  }

  listTeachers(page, limit, filter) {
    return this.http.get(`/teachers?page=${page}&limit=${limit}&filter=${filter}`);
  }

  create(data) {
    return this.http.post('/users', data);
  }

  update(data, id) {
    return this.http.patch(`/users/${id}`, data);
  }

  delete(id) {
    return this.http.delete(`/users/${id}`);
  }

  searchAgent(code) {
    return this.http.get(`/users?role=agent&dni=${code}&limit=0`);
  }

  modules() {
    return this.http.get(`/cms/ui`);
  }

  searchUsers(filter) {
    return this.http.get(`/users?filter=${filter}`);
  }

  searchUserByDni(search) {
    return this.http.get(`/teachers/search-dni?search=${search}`);
  }

  listNotes() {
    return this.http.get(`/teachers/list`);
  }

  updateNotes(id, data) {
    return this.http.patch(`/teachers/${id}`, data);
  }

  listSectionsByCampus(yeardId) {
    return this.http.get(`/teachers/list-section-campus?school_year_id=${yeardId}`);
  }

  listCoursesByYear(yeardId, sectionId) {
    return this.http.get(`/teachers/list-courses?school_year_id=${yeardId}&&section_id=${sectionId}`);
  }

  createProfile(data){
    return this.http.post('/teachers', data);
  }

  listProfiles (yeardId, userId){
    return this.http.get(`/teachers/list-profile?school_year_id=${yeardId}&user_id=${userId}`)
  }

  deleteProfile(id) {
    return this.http.delete(`/teachers/${id}`);
  }
}

export default new UserService();
