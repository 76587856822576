import React, {useState, useEffect} from "react";
import EnrollStudentModal from "../components/university/Enrolls/EnrollStudentsModal";
import { Button, TextField, FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import Enroll from '../models/Enroll';
// import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from "yup";
import { Formik, Form } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePickerField from '../core/widgets/datepicker.widget'
import 'dayjs/locale/es-mx';
import dayjs from 'dayjs/';
import * as yup from "yup";
import InputField from '../core/widgets/input.widget';
import SelectField from '../core/widgets/select.widget';

const EnrollSchema = yup.object().shape({
  // userFullName: yup.string().required('Campo requerido'),
  schoolYearId: yup.string().required('Campo obligatorio'),
  createdAt: yup.string().required('Campo obligatorio'),
  sectionId: yup.string().required('Campo obligatorio'),
  enrollAmount: yup.string().required('Campo obligatorio'),
  // enrollType: yup.number().required('Campo obligatorio'),
  pensionAmount: yup.string().required('Campo obligatorio'),
  // presentialPensionAmount: yup.string().required('Campo obligatorio'),
});

const EnrollForm = (props) => {
  console.log(props);
  const [enroll, setEnroll] = useState(props.enroll);
  const [student, setStudent] = useState({})
  const [studentName, setStudentName] = useState(props.enroll.userFullName)
  const [openModal, setOpenModal] = useState(false);
  const [yearId, setyearId] = useState('');

  useEffect(() => {
    if(!openModal) {
      setEnroll({
        ...enroll,
        userId: student.id,
        userFullName:  student.fullName
      })
    }

  }, [openModal])

  const selectEstudent = item => {
    setOpenModal(false);
    setStudent(item);
    setStudentName(item.fullName);
  }

  const changeOpenModal = val => {
    setOpenModal(val)
  }

  return (
    <>
      <EnrollStudentModal
        data={props.data}
        pagination={props.pagination}
        changePage={props.changePage}
        changeSearch={props.changeSearch}
        selectEstudent={selectEstudent}
        isOpen={openModal}
        changeOpenModal={changeOpenModal}
      />
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Nueva Matricula</h3>
        </div>
        <Formik
          initialValues={{
            ...props.enroll
          }}
          onSubmit={(values) => {
            values.fullName = student.fullName;
            values.userId = student.id;
            values.createdAt = dayjs(values.createdAt).format('YYYY-MM-DD THH:mm:ssZ[Z]');
            values.endAt = values.endAt ? dayjs(values.endAt).format('YYYY-MM-DD') : "";
            props.getEnroll(values);
          }}
          validationSchema={EnrollSchema}
          enableReinitialize={true}
        >
          {
            ({handleChange, errors, touched, values}) => (
              <Form className="card-body">
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Fecha de Matricula<span className="text-danger">*</span></label>
                  <div className="col-md-7">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale='es-mx'
                    >
                      <DatePickerField
                        name='createdAt'
                        value={values.createdAt}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Alumno <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                    <TextField name="userFullName" value={studentName} fullWidth disabled variant='outlined' size='small' />
                  </div>
                  <div className="col-md-2">
                    <Button type="button" className="mr-1 btn btn-primary" onClick={() => setOpenModal(true)} >
                      Buscar
                    </Button>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Año Lectivo <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                    <FormControl fullWidth>
                      <Select
                        value={values.schoolYearId}
                        name="schoolYearId"
                        size="small"
                        onChange={e => {
                          setyearId(e.target.value);
                          handleChange(e);
                        }}
                        error={touched.schoolYearId && Boolean(errors.schoolYearId)}
                      >
                        <MenuItem value={''}>Selecccionar año lectivo</MenuItem>
                        {
                          props.schoolYear?.map((year) => (
                            <MenuItem key={year.id} value={year.id}>{year.name}</MenuItem>
                          ))
                        }
                      </Select>
                      {errors.schoolYearId &&<FormHelperText className="text-danger text-error">{errors.schoolYearId}</FormHelperText> }
                    </FormControl>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Nivel <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                    <FormControl fullWidth>
                      <Select
                        name="levelId"
                        size="small"
                        value={values.levelId}
                        onChange={e => {
                          props.changeGrades(e.target.value);
                          handleChange(e);
                        }}
                      >
                        <MenuItem value={''}>Selecccionar Nivel</MenuItem>
                        {
                          props.levels?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Grado <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                    <FormControl fullWidth>
                      <Select
                        name="gradeId"
                        size="small"
                        value={values.gradeId}
                        onChange={e => {
                          props.changeSections(e.target.value, yearId);
                          handleChange(e);
                        }}
                      >
                        <MenuItem value={''}>Selecccionar Grado</MenuItem>
                        {
                          props.grades?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Sección <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                    <SelectField
                      name="sectionId"
                      data={props.sections?.map(item => {
                        return {key: item.name, value: item.id}
                      })}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Monto de Matricula <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                    <InputField type="number" name="enrollAmount" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Monto de Pensión <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                    <InputField name="pensionAmount" type="number"/>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Círculo <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                    <FormControl fullWidth>
                      <Select
                        name="isScholarship"
                        size="small"
                        value={values.isScholarship}
                        onChange={e => handleChange(e)}
                      >
                        <MenuItem value="0">NO</MenuItem>
                        <MenuItem value="1"> CEM Basico I</MenuItem>
                        <MenuItem value="2">CEM Basico II</MenuItem>
                        <MenuItem value="3">CEM Intermedio I</MenuItem>
                        <MenuItem value="4">CEM Intermedio II</MenuItem>
                        <MenuItem value="5">CEM Intermedio III</MenuItem>
                        <MenuItem value="6">CEM Intermedio IV</MenuItem>
                        <MenuItem value="7">CEM Avanzado I</MenuItem>
                        <MenuItem value="8">CEM Avanzado II</MenuItem>
                        <MenuItem value="9">CEM Avanzado III</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Canal de venta
                  </label>
                  <div className="col-md-7">
                    <InputField name="saleChannel" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Observaciones</label>
                  <div className="col-md-7">
                    <InputField name="comment" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Estado <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                  <FormControl fullWidth>
                      <Select
                        name="status"
                        size="small"
                        value={values.status}
                        onChange={e => handleChange(e)}
                      >
                        <MenuItem value="">Seleccionar estado</MenuItem>
                        <MenuItem value="1"> Activo</MenuItem>
                        <MenuItem value="2">Pendiente</MenuItem>
                        <MenuItem value="3">Exonerado</MenuItem>
                        <MenuItem value="4">Retirado</MenuItem>
                        <MenuItem value="5">Anulado</MenuItem>
                        <MenuItem value="6">Reservado</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Fecha de Retiro<span className="text-danger"></span></label>
                  <div className="col-md-7">
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale='es-mx'
                      >
                        <DatePickerField
                          name='endAt'
                          value={values.endAt}
                        />
                      </LocalizationProvider>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4 col-form-label"></label>
                  <div className="col-md-7">
                    <Button type="submit" className="mr-1 btn btn-primary">
                      Guardar
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </>
  );
};


export default EnrollForm
