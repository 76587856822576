import { useState, useEffect, forwardRef } from "react";
import { useSelector } from "react-redux";
import { Box, Container, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {connect} from 'react-redux';
import { TeacherListToolbar } from "../components/teacher/teacher-list-toolbar";
import { TeacherListResults } from "../components/teacher/teacher-list-results";
import { TeacherDialog } from "../components/teacher/teacher-dialog";
import UserService from "../services/UserService";

const initialValues = {
  firstName: "",
  lastName: "",
  surname: "",
  fullName: "",
  dni: "",
  role: 'teacher'
};

const Teachers = (props) => {

  const { user } = useSelector((state) => state.user);
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState(initialValues);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState('');
  
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const getTeachers = async (p, l, s, f) => {
    try {

      const response = await UserService.listTeachers(p, l, s, f);
      setData(response.data);
      setCustomers(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const changePage = (page) => {
    setPage(page);
  };
  const changeLimit = (limit) => {
    setLimit(limit);
  };

  const handleInput = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handleModal = () => {
    setOpen(true);
  };

  const handleModalEdit = (p) => {
    setCustomer(p);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    setCustomer(initialValues);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const showMessage = (txt, action) => {
    setMessage(txt);
    setAlert(action)
  }

  const searchDni = async(val) => {
    try {
      // customerService.setAccessToken(user.accessToken);
      const response = await UserService.searchUserByDni(val);
      setCustomer({
        ...customer,
        dni: response.data.dni,
        fullName: response.data.fullName,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        surname: response.data.surname
      })
    } catch (error) {
      
    }
  }

  const saveCustomer = async (data) => {
    let message = '';
    try {
      // customerService.setAccessToken(user.accessToken);
      if (data.id) {
        delete data.password;
        // 
        await UserService.update(data, data.id);
        message = 'Profesor Actualizado!';
      } else {
        // await customerService.newCustomer(data);
        data.role = 'teacher';
        // data.campusId = '1';
        data.gender = 'M';
        data.phone ='333';
        await UserService.create(data);
        message = 'Profesor Guardado!';
      }
      getTeachers(page, limit, search);
      handleModalClose();
      setOpenAlert(true);
      showMessage(message, "success");
    } catch (error) {
      console.log(error);
      setOpenAlert(true);
      showMessage("Se produjo un error!", "error");
    }
  };

  useEffect(() => {
    getTeachers(page, limit, search);
  }, [page, limit, search]);

  useEffect(() => {
    // Init Services
    // BI

    UserService.init(props.user.accessToken, props.campus.id);
    (async function getRecords() {
      await getTeachers(page,limit, search);
      // await getlevels();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user, props.campus]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <TeacherListToolbar onInput={handleInput} openModal={handleModal} />
          <TeacherDialog
            open={open}
            closeModal={handleModalClose}
            customer={customer}
            saveCustomer={saveCustomer}
            searchDni={searchDni}
          />
          <Box sx={{ mt: 3 }}>
            <TeacherListResults
              customers={customers}
              changePage={changePage}
              changeLimit={changeLimit}
              count={data.recordsTotal || 0}
              open={open}
              closeModal={handleModalClose}
              openModalEdit={handleModalEdit}
              customer={customer}
            />
          </Box>
        </Container>
      </Box>
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user,
    campus: state.campus,
    filters: state.filters
  };
};

export default connect(mapStateToProps)(Teachers);
