import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from "classnames";
import SchoolYearTable from "../tables/SchoolYearTable";
import SchoolYearService from "../services/SchoolYearService";
import { useUI, usePageUI } from '../app/context/ui';
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Swal from 'sweetalert2';

const SchoolYearSchema = yup.object().shape({
  name: yup
    .string()
    .required("Campo requerido y solo números")
    .max(4, "El nombre debe tener 4 caracteres")
    .min(4, "El nombre debe tener 4 caracteres"),
  status: yup.number().min(1, "Debe ser un número"),
});

const SchoolYearPage = (props) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(SchoolYearSchema),
  });
  const [activeTab, setActiveTab] = useState(1);
  const [schoolYearData, setSchoolYearData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [schoolYear, setSchoolYear] = useState({
    id: "",
    name: "",
    status: 2,
  });

  useEffect(() => {
    SchoolYearService.init(props.user.accessToken, props.campus.id)
    getSchoolYears();
  }, [props.campus, props.user]);

  const handlerChange = (e) => {
    setSchoolYear({
      ...schoolYear,
      [e.target.name]: e.target.value,
    });
  };

  const getSchoolYears = async () => {
    setLoading(true)
    try {
      const response = await SchoolYearService.list();
      setSchoolYearData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async() => {
    blockUI.current.open(true, 'Estamos guardando...');
    let response = '';
    try {
      if (schoolYear.id) {
        response = await SchoolYearService.update(schoolYear, schoolYear.id);
      } else {
        response = await SchoolYearService.create(schoolYear);
      }
      if (response) {
        setActiveTab(1);
        getSchoolYears();
        blockUI.current.open(false);
        alertUI.current.open(true, response.data.message, 'success');
      }
    } catch (error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
    }
  };

  const getData = (data, action) => {
    if(action === 'edit'){
      setSchoolYear(data);
      setActiveTab(2);
    } else {
      deleteSchoolYear(data)
    }
  };

  const deleteSchoolYear = (data) => {
    Swal.fire({
      title: "Estas Seguro?",
      text: "No podrás recuperar este registro eliminado!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#333",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        blockUI.current.open(true, 'Estamos Eliminando....');
        try {
          const response = await SchoolYearService.delete(data.id);
          if (response) {
            getSchoolYears();
            blockUI.current.open(false);
            alertUI.current.open(true, response.data.message, 'success');
          }
        } catch (error) {
          console.log(error);
          blockUI.current.open(false);
          alertUI.current.open(true, 'Error', 'danger');
        }
      }
    });
  };

  const openTabAdd = () => {
    setSchoolYear({
      id: "",
      name: "",
      status: 1,
    });
    setActiveTab(2);
  };

  const {name, status} = schoolYear;

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Años Lectivos</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {props.user.school ? props.user.school : 'YAKHANA'}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Años lectivos
                </li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setActiveTab(1)}
                >
                  Listado
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 2 })}
                  onClick={() => openTabAdd()}
                >
                  Agregar
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className={classnames(["fade show"])}>
              <SchoolYearTable data={schoolYearData} getData={getData} isLoading={loading} />
            </TabPane>
            <TabPane tabId={2} className={classnames(["fade show"])}>
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Nuevo Año lectivo</h3>
                      
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group row">
                          <label className="col-md-3 col-form-label">
                            Nombre <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-9">
                            <input
                              type="text"
                              className={
                                errors.name
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              name="name"
                              value={name}
                              onChange={handlerChange}
                              ref={register}
                              error={errors.name}
                              maxLength={8}
                            />
                            {errors.name && (
                              <span className="invalid-feedback">
                                {errors.name.message}
                              </span>
                            )}
                          </div>
                        </div>
                        {schoolYear.id && (
                          <div className="form-group row">
                            <label className="col-md-3 col-form-label">
                              Estado <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-9">
                              <select
                                className={
                                  errors.status
                                    ? "form-control input-height is-invalid"
                                    : "form-control input-height"
                                }
                                name="status"
                                value={status}
                                onChange={handlerChange}
                                ref={register}
                              >
                                <option value="">Seleccione</option>
                                <option value="1">Activo</option>
                                <option value="2">Inactivo</option>
                              </select>
                              {errors.status && (
                                <span className="invalid-feedback">
                                  {errors.status.message}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="text-center">
                          <button
                            type="submit"
                            className="mr-1 btn btn-primary"
                          >
                            Guardar
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(SchoolYearPage);
