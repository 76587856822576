const Student = {
    id: '',
    dni: '',
    firstName: '',
    lastName: '',
    surname: '',
    birthdate: '',
    gender: '',
    phone: '',
    email: '',
    address: '',
    role: 'student',
    agentId: '',
    agentRelationship: '',
    agentFullName: '',
    schoolFrom: '',
    status: 1
  }

  export default Student
