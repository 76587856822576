import React, { useCallback } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Stack,
  TableContainer,
  Paper,
  Box,
  Toolbar
} from "@mui/material";
import { upperCase } from "lodash";

const DocumentTable = ({ rows, downloadDocument, downloadDocumentBySection }) => {
  return (
    <Box
      style={{ width: "100%" }}
      sx={{
        width: "100%",
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        { rows.length > 0 && 
            <Button
            variant="contained"
            color="info"
            onClick={() => downloadDocumentBySection()}
          >
            Descarga Libreta por Seccion
          </Button>}
      </Toolbar>
      <TableContainer component={Paper}>
        <Table sx={{ maxHeight: 440 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">Numero de Matricula</TableCell>
              <TableCell align="center">DNI</TableCell>
              <TableCell align="center">Apellidos y Nombres</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow
                hover
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell padding="checkbox">{index + 1}</TableCell>
                <TableCell align="center">{row.id}</TableCell>
                <TableCell align="center">{row.userDni}</TableCell>
                <TableCell>{upperCase(row.userFullName)}</TableCell>
                <TableCell align="center">
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => downloadDocument(row.id)}
                    >
                      Descargar
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DocumentTable;
