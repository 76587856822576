import { Formik, Form } from 'formik';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as yup from 'yup';

import { ProfileForm } from "./forms/profile-form";
import { functions } from 'lodash';

const validationSchema = yup.object({
  school_year_id: yup.number().required('El año es obligatorio'),
  section_id: yup.number().required('La seccion es obligatorio'),
  course_id: yup.number().required('El curso es obligatorio')
});

export const ProfileDialog = ({open, sections, courses, customer, closeModal, saveProfile, searchSection, searchCourses}) => {

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} scroll="paper">
      <Formik
        initialValues={customer}
        onSubmit={
          (values) => {
            // console.log(values);
            saveProfile(values);
          }
        }
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(props) => (
          <Form >
            <DialogTitle>
              Nuevo Curso
            </DialogTitle>
            <DialogContent>
              <ProfileForm
                {...props}
                searchSection={searchSection}
                sections={sections}
                courses={courses}
                searchCourses={searchCourses}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal} variant="outlined">
                Cancelar
              </Button>
              <Button type="submit" variant="contained">
                Guardar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

