import React, { useState} from "react";
import Spinner from '../components/Shared/Spinner';
import Pagination from 'react-js-pagination';

const UserTable = (props) => {
  const data = props.data;
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(25);

  const handlerChangeSearch = e => {
    setSearch(e.target.value)
    props.changeSearch(e.target.value)
  }

  const handlerChange = e => {
    setLimit(e.target.value)
    props.changeLimit(e.target.value)
  }

  const handlePageChange = page => {
    props.changePage(page)
  }

  const editUser = (item) => {
    props.getData(item, "edit");
  };

  const deleteUser = (item) => {
    props.getData(item, "delete");
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="input-group">
                <input type="search" className="form-control" onChange={handlerChangeSearch} placeholder="Buscar" value={search} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right mb-2">
      <div className="offset-10 col-sm-2">
        <select className="form-control input-height" onChange={handlerChange} value={limit}>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    </div>
      <div className="table-responsive card">
        <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
          <thead>
            <tr>
              <th>Código</th>
              <th>Apellidos y Nombres</th>
              <th>Rol</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.dni}</td>
                  <td>{item.fullName}</td>
                  <td>{item.role}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-icon btn-sm"
                      title="Edit"
                      onClick={() => editUser(item)}
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      onClick={() => deleteUser(item)}
                      type="button"
                      className="btn btn-icon btn-sm js-sweetalert"
                      title="Delete"
                      data-type="confirm"
                    >
                      <i className="fa fa-trash-o text-danger"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        { props.isLoading && <Spinner />}
        {
          data.length > 0 &&
          <div className="d-flex justify-content-center mt-20">
            <Pagination
              activePage={props.pagination.activePage}
              itemsCountPerPage={parseInt(props.pagination.itemsCountPerPage)}
              totalItemsCount={props.pagination.totalItemsCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass='page-item '
              linkClass='page-link'
            />        
          </div>
        }
      </div>
    </>
  );
};

export default UserTable;
