import React, { useState, useEffect } from "react";
import Expense from '../models/Expense';
import { Formik, Form } from "formik";
import InputField from "../core/widgets/input.widget";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePickerField from '../core/widgets/datepicker.widget'
import 'dayjs/locale/es-mx';
import dayjs from 'dayjs/';
import * as yup from "yup";

const ExpenseSchema = yup.object().shape({
  amount: yup.number().required('Ingrese el monto de egreso'),
  description: yup.string().required('Ingrese detalle del egreso'),
  date: yup.string().required('Ingrese la fecha de egreso')
});

const ExpenseForm = ({expenseData, isValid, getExpenseData }) => {
 
  const dataInicial = Expense;
  const [expense, setExpense] = useState(dataInicial);

  useEffect(() => {
    (async function getRecords() {
      await setExpense(expenseData);
    })();
  }, [expenseData])

  useEffect(() => {
    setExpense(dataInicial);
  }, [isValid])

  const handlerChange = (e) => {
    setExpense({
      ...expense,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Nuevo Egreso</h3>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{...expenseData}}
                validationSchema={ExpenseSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  console.log(values);
                  values.date = dayjs(values.date).format('YYYY-MM-DD');
                  getExpenseData(values);
                }}
              >
                {() => (
                <Form>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Fecha de Egreso<span className="text-danger">*</span></label>
                    <div className="col-md-7">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale='es-mx'
                      >
                        <DatePickerField
                          name='date'
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Monto <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <InputField name="amount" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Descripción <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <InputField name="description" />
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="mr-1 btn btn-primary">
                      Guardar
                    </button>
                  </div>
                </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpenseForm;
