import React, { useState, useEffect } from "react";
import Campus from '../models/Campus';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const CampusSchema = yup.object().shape({
  code: yup.string().required("Campo requerido").max(3, 'Máximo dos caracteres'),
  name: yup.string().required("Campo requerido"),
  ruc: yup.number()
  .typeError('El RUC solo debe contener números.')
  .test('len', 'El RUC debe tener 11 digitos.', val => val && val.toString().length === 11 ),
  address: yup.string().required("Campo requerido"),
  company: yup.string().required('Campo Requerido'),
  enrollAmount: yup.string().required('Campo requerido'),
  pensionAmount: yup.string().required('Campo requerido'),
  phone: yup.number()
  .min(6, 'minimo 6 digitos')
  .typeError('El teléfono solo debe contener números.')
  .test('len', 'El teléfono debe tener de 6 a 9 digitos.', val => val && val.toString().length <= 9 && val.toString().length >= 6  ),
});

const CampusForm = (props) => {
  
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(CampusSchema),
  });
  const dataInicial = Campus;
  const [campus, setCampus] = useState(dataInicial);

  useEffect(() => {
    setCampus(props.campusData);
  }, [props.campusData])

  useEffect(() => {
    setCampus(dataInicial);
  }, [props.isValid])

  const handlerChange = (e) => {
    setCampus({
      ...campus,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    props.getCampusData(campus);
  };

  const {code, name, company, address, ruc, phone, enrollAmount, pensionAmount} = campus;

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="row clearfix">
          <div className="col-lg-7 col-md-7 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Nueva Sede</h3>
              </div>
              <div className="card-body">
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Código <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        value={code}
                        className={
                          errors.code ? "form-control is-invalid" : "form-control"
                        }
                        name="code"
                        onChange={handlerChange}
                        ref={register}
                        error={errors.code}
                        maxLength={3}
                      />
                      {errors.code && (
                        <span className="invalid-feedback">
                          {errors.code.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Nombre <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        value={name}
                        className={
                          errors.name ? "form-control is-invalid" : "form-control"
                        }
                        name="name"
                        onChange={handlerChange}
                        ref={register}
                        error={errors.name}
                      />
                      {errors.name && (
                        <span className="invalid-feedback">
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      RUC <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className={
                          errors.ruc ? "form-control is-invalid" : "form-control"
                        }
                        name="ruc"
                        value={ruc}
                        onChange={handlerChange}
                        ref={register}
                        error={errors.ruc}
                        maxLength={11}
                      />
                      {errors.ruc && (
                        <span className="invalid-feedback">
                          {errors.ruc.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Razón Social <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        value={company}
                        className={
                          errors.company ? "form-control is-invalid" : "form-control"
                        }
                        name="company"
                        onChange={handlerChange}
                        ref={register}
                        error={errors.company}
                      />
                      {errors.company && (
                        <span className="invalid-feedback">
                          {errors.company.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Dirección <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        value={address}
                        className={
                          errors.address ? "form-control is-invalid" : "form-control"
                        }
                        name="address"
                        onChange={handlerChange}
                        ref={register}
                        error={errors.address}
                      />
                      {errors.address && (
                        <span className="invalid-feedback">
                          {errors.address.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Teléfono <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        value={phone}
                        className={
                          errors.phone ? "form-control is-invalid" : "form-control"
                        }
                        name="phone"
                        onChange={handlerChange}
                        ref={register}
                        error={errors.phone}
                        maxLength={9}
                      />
                      {errors.phone && (
                        <span className="invalid-feedback">
                          {errors.phone.message}
                        </span>
                      )}
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Pagos</h3>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-md-5 col-form-label">
                    Matrícula <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                    <input
                      type="number"
                      value={enrollAmount}
                      className={
                        errors.enrollAmount ? "form-control is-invalid" : "form-control"
                      }
                      name="enrollAmount"
                      onChange={handlerChange}
                      ref={register}
                      error={errors.enrollAmount}
                    />
                    {errors.enrollAmount && (
                      <span className="invalid-feedback">
                        {errors.enrollAmount.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-5 col-form-label">
                    Pensión <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-7">
                    <input
                      type="number"
                      value={pensionAmount}
                      className={
                        errors.pensionAmount ? "form-control is-invalid" : "form-control"
                      }
                      name="pensionAmount"
                      onChange={handlerChange}
                      ref={register}
                      error={errors.pensionAmount}
                    />
                    {errors.pensionAmount && (
                      <span className="invalid-feedback">
                        {errors.pensionAmount.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button type="submit" className="mr-1 btn btn-primary">
              Guardar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CampusForm;
