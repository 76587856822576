import React, {useState} from 'react'
import Spinner from '../components/Shared/Spinner';

const SchoolYearTable = (props) => {
  const [show, setShow] = useState(false)
  const [limit, setLimit] = useState(25)
  const data = props.data

  const editSchoolyear = (item) => {
    props.getData(item, 'edit');
  }

  const deleteSchoolyear = (item) => {
    props.getData(item, 'delete');
  }

  return (
    <>
    <div className="table-responsive card">
      <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{ item.name }</td>
                  <td>{ item.status === 1 ? 'Activo' : 'Inactivo' }</td>
                  <td>
                    <button type="button" className="btn btn-icon btn-sm" title="Edit" onClick={() => editSchoolyear(item)}><i className="fa fa-edit"></i></button>
                    {/* <button onClick={() => deleteSchoolyear(item)} type="button" className="btn btn-icon btn-sm js-sweetalert" title="Delete" data-type="confirm"><i className="fa fa-trash-o text-danger"></i></button> */}
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      { props.isLoading && <Spinner />}
    </div>
    </>
  )
}

export default SchoolYearTable