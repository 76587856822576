import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import InputField from "../core/widgets/input.widget";
import * as yup from "yup";

const ProductSchema = yup.object().shape({
  name: yup.string().required("Campo requerido"),
  amount: yup.number()
  .typeError('El monto debe contener números.')
  .test('len', 'El monto debe contener números.', val => val && val.toString().length <= 6 ),
});

const ProductForm = ({ productData, getProductData }) => {
  const [product, setProduct] = useState(productData);

  useEffect(() => {
    setProduct(productData);
  }, [productData])

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Productos</h3>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{...productData}}
                validationSchema={ProductSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  getProductData(values);
                }}
              >
                {() => (
                <Form>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Nombre <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <InputField name="name" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Monto <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <InputField name="amount" />
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="mr-1 btn btn-primary">
                      Guardar
                    </button>
                  </div>
                </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductForm;
