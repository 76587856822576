import React, { useState } from "react";
import Pagination from "react-js-pagination";
import Spinner from '../components/Shared/Spinner';

const SectionTable = (props) => {
  const [show, setShow] = useState(false);
  const [limit, setLimit] = useState(25);
  const data = props.data;

  const handlerChange = (e) => {
    setLimit(e.target.value);
    props.changeLimit(e.target.value);
  };

  const handlePageChange = (page) => {
    props.changePage(page);
  };

  const editSection = (item) => {
    props.getData(item, "edit");
  };

  const deleteSection = (item) => {
    props.getData(item, "delete");
  };

  return (
    <>
      <div className="text-right mb-2">
        <div className="offset-10 col-sm-2">
          <select
            className="form-control input-height"
            onChange={handlerChange}
            value={limit}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>
      <div className="table-responsive card">
        <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Código</th>
              <th>Código corto</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.code}</td>
                  <td>{item.shortCode}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-icon btn-sm"
                      title="Edit"
                      onClick={() => editSection(item)}
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      onClick={() => deleteSection(item)}
                      type="button"
                      className="btn btn-icon btn-sm js-sweetalert"
                      title="Delete"
                      data-type="confirm"
                    >
                      <i className="fa fa-trash-o text-danger"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        { props.isLoading && <Spinner /> }
        <div className="d-flex justify-content-center mt-20">
          <Pagination
            activePage={props.pagination.activePage}
            itemsCountPerPage={parseInt(props.pagination.itemsCountPerPage)}
            totalItemsCount={parseInt(props.pagination.totalItemsCount)}
            pageRangeDisplayed={
              props.pagination.numPage < 5 ? props.pagination.numPage : 5
            }
            onChange={handlePageChange}
            itemClass="page-item "
            linkClass="page-link"
          />
        </div>
      </div>
    </>
  );
};

export default SectionTable;
