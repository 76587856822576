import React, {useState, useEffect, useRef} from 'react';
import { Modal, ModalHeader, ModalBody} from "reactstrap";
import { Formik, Form } from 'formik';
import InputField from '../../core/widgets/input.widget';
import * as yup from "yup";

const PaymentSchema = yup.object().shape({
  status: yup.number(),
  description: yup.string()
});

const PaymentStatusModal = ({handlerOpenModalStatus, isOpen, getPayment, status, className, payment, title}) => {

  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const toggle = () => {
    handlerOpenModalStatus(!show)
    setShow(!show);
    setData([]);
  };
  
  const isSending = useRef(false);

  useEffect(() => {
    if (isOpen) {
      setData(payment);
    }
    setShow(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if(isSending.current === true) {
      isSending.current = false;
      getPayment(data);
    }
  }, [data]);
  
  return(
    
    <>
      <Modal isOpen={show} toggle={toggle} className={className} size="lg">
        <ModalHeader toggle={toggle}>{title} {data.month}</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              description: ""
            }}
            validationSchema={PaymentSchema}
            onSubmit={(values) => {
              isSending.current = true;
                setData({
                  ...data,
                  status: status,
                  description: values.description,
                  total: 0
                });
                handlerOpenModalStatus(!show);
            }}
          >
            {(values) => (
              <Form>
                <div className="col-md-12">
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label">
                      Descripción
                    </label>
                    <div className="col-md-8">
                      <InputField name="description" />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="mr-1 btn btn-primary">
                    Guardar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </>
  )
}

export default PaymentStatusModal;
