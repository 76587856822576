import AppService from './AppService';

class StudentService extends AppService {
  list(page, limit, filter = null) {
    if (filter === null) {
      return this.http.get(`/users?role=student&page=${page}&limit=${limit}`);
    } else {
      return this.http.get(`/users?role=student&page=${page}&limit=${limit}&filter=${filter}`);
    }
    
  }

  create(data) {
    return this.http.post('/auth/sign-up', data);
  }

  read(id) {
    return this.http.get(`/users/${id}`);
  }
}

export default new StudentService();