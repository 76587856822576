export const adminMenu = [
	{
		"id": 1,
		"icon": "credit-card",
		"label": "Pagos",
		"to": "/payments",
	},
	{
		"id": 8,
		"icon": "money",
		"label": "Egresos",
		"to": "/expenses",
	},
	{
		"id": 2,
		"icon": "list-ul",
		"label": "Secciones",
		"to": "/secciones",
	},
	{
		"id": 3,
		"icon": "camera-retro",
		"label": "Años lectivos",
		"to": "/schoolYear",
	},
	{
		"id": 4,
		"icon": "user-circle-o",
		"label": "Usuarios",
		"to": "/users",
	},
	{
		"id": 5,
		"icon": "university",
		"label": "Sedes",
		"to": "/campuses",
	},
	{
		"id": 6,
		"icon": "list-ul",
		"label": "Tipos de Pagos",
		"to": "/payments/type",
	},
	{
		"id": 7,
		"icon": "cog",
		"label": "Ajustes",
		"to": "/settings",
	},
	
]
export const universityMenu = [
	{
		"id": 1,
		"icon": "dashboard",
		"label": "Dashboard",
		"to": "/",
	},
	{
		"id": 4,
		"icon": "users",
		"label": "Alumnos",
		"to": "/students",
	},
	{
		"id": 6,
		"icon": "calendar-check-o",
		"label": "Matricula",
		"to": "/matricula",
	},
];
